import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jQuery from 'jquery';
import { RootScope } from '../shared/RootScope.service';
import { BusyIndicatorService } from 'swx.front-end-lib';
import { ApiService } from '../shared/Api.service';

declare var tableau: any;

@Component({
    templateUrl: 'Analytics.component.html'
})
export class AnalyticsComponent implements OnInit {
    viz;
    
    constructor(
        private elementRef: ElementRef,
        private route: ActivatedRoute,
        private $root: RootScope,
        private busyIndicator: BusyIndicatorService,
        private api: ApiService,
    ) {
    }

    ngOnInit(): void {
	    this.$root.currentClient.$promise.then(() => {
            this.route.params.subscribe(params => {
	            this.loadDashboard(params['id']);
            });
        });

        var analyticsOptions = jQuery(this.elementRef.nativeElement).find('.analyticsOptions');
        
        // HACK to position export button
        setTimeout(() => {
            analyticsOptions.css('left', jQuery('#navigationBar .navbar-nav').width() + 25);
        }, 100);
    }

    loadDashboard(id) {
	    if (id) {
		    var dashboard = this.$root.currentClient.ClientPortalTableauDashboards.find(d => d.Id === parseInt(id));

		    if (dashboard) {
			    if (this.$root.currentClient.UsePortalTableauTrustedAuthentication) {
				    this.api.TableauTrustedAuthenticationToken.queryObject().$promise.then(response => {
					    var url = dashboard.Url.indexOf('/t/') > -1
						    ? dashboard.Url.replace('/t/',
							    '/trusted/' + response.token + '/t/')
						    : dashboard.Url.replace('/views/',
							    '/trusted/' + response.token + '/views/');
					    this.switchView(url);
				    });
			    } else {
				    this.switchView(dashboard.Url);
			    }
		    }
	    }
    }

    exportToPDF() {
        this.viz.showExportPDFDialog();
    }

    switchView(viewUrl) {
        if (this.viz != null) {
            this.viz.dispose();
            this.viz = null;
        }

        var containerDiv = document.getElementsByClassName("viewWrapper")[0];
		
        this.busyIndicator.setBusy(true);

        var options = {
            hideTabs: true,
            hideToolbar: true,
            device: "desktop",
            onFirstInteractive: () => {
	            this.busyIndicator.setBusy(false);
            }
        };

        this.viz = new tableau.Viz(containerDiv, viewUrl, options);

        window.onmessage = e => {
            setTimeout(() => {
                    if (e.data === 'tableauSamlPost') {
                        if (this.viz != null) {
                            this.viz.dispose();
                            this.viz = null;
                        }

                        this.viz = new tableau.Viz(containerDiv, viewUrl, options);
                    }
                },
                1000);
        };
    };
}
