<page-title></page-title>
<div class="dispatch contentWrapper" [style.display]="airports?.length >= 0 ? '' : 'none'">
    <div class="airports" [style.display]="hideLeftPane ? 'none' : ''">
        <div class="toolbar">
            <a class="tab" *ngFor="let group of $root.currentUser.DispatchAirportProfile?.DispatchAirportGroups|orderBy:'Order'" (click)="switchGroup(group)" [class.active]="selectedGroup?.Id === group.Id">{{group.Name}}</a>
            <div class="dispatchSearch">
                <span class="mdi mdi-magnify"></span>
                <input type="text" name="airportSearch" [(ngModel)]="airportSearch" />
                <div class="button mdi mdi-chevron-down"></div>
            </div>
            <button style="margin: 0 20px;" *ngIf="showShiftSelection && ('DeskSelection'|hasPermission)" (click)="changeShift()">Change Desk</button>
            <span>{{selectedDeskNames}}</span>
        </div>
		<div class="contentMargins">
			<div class="alerts">
				<div *ngFor="let alert of alertsForDisplayedAirports(alerts)|orderBy:'-Date'" class="alert animated pulse" (click)="selectAirport(alert.Airport)" [class.selected]="selectedAirport?.Id === alert.Airport.Id">
					<div class="airportCodeWrapper">
						<span [class]="'hotConditionIcon mdi ' + (alert.Airport.HotResponse.HotConditionIcon || 'mdi-map-marker')" [title]="alert.Airport.HotResponse.HotConditionName" [style.color]="'#' + (alert.Airport.HotResponse.HotConditionColor || 'aaaaaa')"></span>
						<span class="airportCode">{{$root.currentUser.UseIataCode ? alert.Airport.IATACode : alert.Airport.ICAOCode}}</span>
					</div>
					<span class="message">
						{{$root.currentUser.UseLocalTime && selectedAirport?.TimeZone ? (moment.utc(alert.Date)['tz'](selectedAirport?.TimeZone).format('HH:mm') + ' LT') : (moment.utc(alert.Date).format('HH:mm') + ' Z')}}&nbsp;&nbsp;&nbsp;{{alert.Airport.HotResponse?.Message || alert.Airport.HotResponse?.FluidMessage}}
					</span>
					<span class="mdi mdi-close" (click)="removeAlert(alert.Airport.Id)"></span>
				</div>
			</div>
			<div class="adhoc-airports">
				<dispatch-airport *ngFor="let airport of mapAdhocAirports(adhocAirports)|filter:airportSearch|orderBy:['HotResponse.HotConditionRank','-Severity','Type1Hot.MinHot','Type4Hot.MinHot','-IsFreezing','ICAOCode']"
								  [airport]="airport"
								  (click)="selectAirport(airport)"
								  [selected]="selectedAirport?.Id === airport.Id"
								  [adhoc]="true"
								  (remove)="removeAdhocAirport(airport)">
				</dispatch-airport>
			</div>
			<div *ngFor="let subgroup of selectedGroup?.DispatchAirportGroupSubgroups|orderBy:'Order'">
				<div class="subgroup">
					<h4>{{subgroup.Name}}</h4>
					<dispatch-airport *ngFor="let airport of mapAirports(subgroup.DispatchAirportGroupSubgroupAirports)|filter:airportSearch|orderBy:['HotResponse.HotConditionRank','-Severity','Type1Hot.MinHot','Type4Hot.MinHot','-IsFreezing','ICAOCode']"
									  [airport]="airport"
									  (click)="selectAirport(airport)"
									  [selected]="selectedAirport?.Id === airport.Id">
					</dispatch-airport>
				</div>
			</div>
			<div *ngIf="$root.currentUser.DispatchAirportProfile == null">
				<dispatch-airport *ngFor="let airport of airports|filter:airportSearch|orderBy:['HotResponse.HotConditionRank','-Severity','Type1Hot.MinHot','Type4Hot.MinHot','-IsFreezing','ICAOCode']"
								  [airport]="airport"
								  (click)="selectAirport(airport)"
								  [selected]="selectedAirport?.Id === airport.Id">
				</dispatch-airport>
			</div>
        </div>
    </div>
    <dispatch-airport-legend *ngIf="!hideLeftPane" style="position: absolute; bottom: 10px;" [hotConditions]="dispatchMonitor.hotConditions?.$promise | async"></dispatch-airport-legend>
    <div class="border" *ngIf="!hideLeftPane"></div>
    
    <div class="details" [style.left]="hideLeftPane ? 0 : 'calc(56% + 4px)'">
        <div>
            <div class="airportCodeWrapper">
                <span *ngIf="('NowcastGraph'|hasPermission) && selectedAirport && selectedAirport.UseNowcast else without_nowcast" class="nowcastIcon mdi mdi-snowflake"></span>
                <ng-template #without_nowcast>
                    <span [class]="'hotConditionIcon mdi ' + (hotResponse.HotConditionIcon || 'mdi-map-marker')"
                          *ngIf="selectedAirport"
                          [title]="hotResponse.HotConditionName"
                          [style.color]="'#' + (hotResponse.HotConditionColor || 'aaaaaa')">
                    </span>
                </ng-template>
                <span *ngIf="selectedAirport && (hotResponse.Type === 'LWE' || selectedAirport.StationSimulationId || selectedAirport.StationGen2Id || selectedAirport.StationGen3Id || selectedAirport.StationGenVId)" class="plus" [title]="'LWE'|translate">+</span>
    
                <span class="airportCode" *ngIf="selectedAirport">{{$root.currentUser.UseIataCode ? selectedAirport.IATACode : selectedAirport.ICAOCode}}</span>
                <dispatch-airport-weather-icon *ngIf="selectedAirport" [airport]="selectedAirport"></dispatch-airport-weather-icon>
                <div class="hotConditions">
                    <span class="mdi barrelIcing mdi-message" *ngIf="selectedAirport?.BarrelIcingConditionsPresent" [title]="'Barrel icing'|translate"><span class="code">BI</span></span>
                    <span class="mdi activeFrost mdi-message" *ngIf="selectedAirport?.ActiveFrostConditionsPresent" [title]="'Active frost'|translate"><span class="code">AF</span></span>
                </div>
                <div class="forecastMessage" *ngIf="$root.currentClient?.DispatchIncludeForecastMessage && hotResponse?.ForecastMessage">{{hotResponse.ForecastMessage}}</div>
                <button type="button" class="refresh" (click)="manualRefresh()"><span class="mdi mdi-refresh"></span></button>
                <div class="requestTime" *ngIf="selectedAirport">
                    <span class="mdi mdi-clock"></span>
                    {{$root.currentUser.UseLocalTime && selectedAirport.TimeZone ? (moment.utc(selectedAirport.RequestDateTime)['tz'](selectedAirport.TimeZone).format($root.dateFormat + ' ' + $root.timeFormat) + ' LT') : (moment.utc(selectedAirport.RequestDateTime).format($root.dateFormat + ' ' + $root.timeFormat) + ' Z')}}
                </div>
            </div>
			
			<portlets>
				<portlet [title]="'HOTs'" [name]="'hots'" class="hots" *ngIf="selectedAirport">
					<form #form #ngOfflineHotForm="ngForm" (submit)="getManualHotResponse()">
						<table class="table table-striped table-hover">
							<tr *ngIf="!manualEntryMode">
								<td class="col-md-3">{{hotResponse.Type}} {{'Timestamp'|translate}}</td>
								<td class="col-md-9">{{$root.currentUser.UseLocalTime && selectedAirport.TimeZone ? (moment.utc(hotResponse.DateTime)['tz'](selectedAirport.TimeZone).format($root.timeFormat) + ' LT') : (moment.utc(hotResponse.DateTime).format($root.timeFormat) + ' Z')}}</td>
							</tr>
							<tr *ngIf="hotResponse.Temperature !== null">
								<td class="col-md-3">{{manualEntryMode ? 'Manual Override' : hotResponse.Type}} {{'Temperature'|translate}}</td>
								<td class="col-md-9">
									<button *ngIf="!manualEntryMode" type="button" class="editTemperature" (click)="goOffline()"><span class="mdi mdi-pencil"></span></button>
									<span *ngIf="manualEntryMode">
										<input type="number" [(ngModel)]="temperature" (ngModelChange)="getManualHotResponse()" name="temperature" required step="1" [min]="minTemp" style="width: 70px;" />
										{{$root.currentUser.UseFahrenheit ? ' \u00B0F' : ' \u00B0C'}}
									</span>
									<span *ngIf="!manualEntryMode">{{hotResponse.Temperature|formatTemperature:hotResponse.Type}}</span>
								</td>
							</tr>
							<tr *ngIf="hotResponse.WeatherReport || hotResponse.WeatherTypeName">
								<td class="col-md-3">{{manualEntryMode ? 'Manual Override' : hotResponse.Type}} {{'Weather'|translate}}</td>
								<td class="col-md-9">
									<div class="weatherTypeSelection" *ngIf="manualEntryMode">
										<div class="caption"><span class="mdi mdi-weather-cloudy mdi-24px"></span> {{'Precipitation'|translate}}</div>
										<div class="weatherType">{{selectedWeatherMenuItem?.Name || ''}}</div>
										<button type="button" (click)="showWeatherTypePopup()" class="showWeatherTypePopup"><span class="mdi mdi-pencil"></span></button>
									</div>
									<div class="intensitySelection" *ngIf="manualEntryMode && selectedWeatherMenuItem && (selectedWeatherMenuItem.Items?.length > 0 || selectedWeatherMenuItem.SnowVisibilityMenu != null)">
										<div class="caption"><span class="mdi mdi-weather-snowy mdi-24px"></span> {{selectedWeatherMenuItem.SnowVisibilityMenu.SelectedIntensityTitle}}</div>
										<div class="intensity">{{selectedVisibility?.Name ?? selectedIntensity?.Name || ''}}</div>
										<button type="button" (click)="showIntensityPopup()" class="showIntensityPopup"><span class="mdi mdi-pencil"></span></button>
									</div>
									<button *ngIf="!manualEntryMode" type="button" class="editWeatherType" (click)="editWeatherType()"><span class="mdi mdi-pencil"></span></button>
									<span *ngIf="!manualEntryMode">{{hotResponse.Type == 'METAR' ? hotResponse.WeatherReport : hotResponse.WeatherTypeName}}</span>
								</td>
							</tr>
							<tr *ngIf="selectedAirport.MetarReadingString">
								<td class="col-md-3">{{'METAR'|translate}}</td>
								<td class="col-md-9">{{selectedAirport.MetarReadingString}}</td>
							</tr>
						</table>
                        <div class="row">
                            <div class="col">
                                <div class="fluidList">
                                    <div class="row" *ngIf="showMetarRefHot">
                                        <ng-container *ngIf="!manualEntryMode">
                                            <div class="col col-40"></div>
                                            <div class="col">
                                                <h3 class="center-text">{{hotResponse?.Type === 'METAR' ? 'METAR' : 'LWE' }}</h3>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="manualEntryMode">
                                            <div class="col col-40"></div>
                                            <div class="col">
                                                <h3 class="center-text">Manual Override</h3>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="row" *ngFor="let hot of hotResponse?.HotResponseHots|orderBy:['FluidOrder', 'FluidType', 'FluidName', '-SortableDilution']">
                                        <div class="col col-40 fluid"
                                             [class.type1]="hot.FluidType === 'Type1'"
                                             [class.type2]="hot.FluidType === 'Type2'"
                                             [class.type3]="hot.FluidType === 'Type3'"
                                             [class.type4]="hot.FluidType === 'Type4'"
                                             [class.generic]="hot.IsGeneric"
                                             [class.composite]="hot.IsComposite">
                                            <div style="font-weight: bold; font-size: 18px; margin-bottom: 7px;">
                                                TYPE {{formatFluidType(hot.FluidTypeNumber)}}
                                                <div class="lineSample"
                                                     [class.type1]="hot.FluidType === 'Type1'"
                                                     [class.type2]="hot.FluidType === 'Type2'"
                                                     [class.type3]="hot.FluidType === 'Type3'"
                                                     [class.type4]="hot.FluidType === 'Type4'"
                                                     [class.dilution75]="hot.FluidDilution === '75/25'"
                                                     [class.dilution50]="hot.FluidDilution === '50/50'"
                                                     [class.composite]="hot.IsComposite"
                                                     [class.generic]="hot.IsGeneric"></div>
                                            </div>
                                            <div>
                                                <span>{{hot.FluidName}}</span>
                                                <span *ngIf="$root.currentClient.ShowFluidConcentration && hot.FluidType != 'Type1'">{{hot.FluidDilution}}</span>
                                            </div>
                                        </div>

                                        <div class="col hot" style="margin-left: 10px;">
                                            <div class="holdoverTimeMessage backgroundColour" *ngIf="!isEmpty(hot.NonHotMessage) || !isEmpty(hot.ReferenceMetarMessage)">
                                                <span style="white-space: pre-wrap;">{{hot.NonHotMessage}}</span>
                                            </div>
                                            <div *ngIf="!isEmpty(hot.MinHot)">
                                                <div style="margin: 0px 0px 9px 15px; overflow: hidden;">
                                                    <span [style.display]="getHotStyle(hot).min.display" [style.width.%]="getHotStyle(hot).min.width" [style.text-align]="getHotStyle(hot).min['text-align']" [style.float]="getHotStyle(hot).min.float"><span class="backgroundColour">{{hot.MinHot}} {{'MIN'|translate}}</span></span>
                                                    <span [style.display]="getHotStyle(hot).max.display" [style.width.%]="getHotStyle(hot).max.width" [style.text-align]="getHotStyle(hot).max['text-align']" [style.float]="getHotStyle(hot).max.float" *ngIf="!isEmpty(hot.MaxHot)"><span class="backgroundColour">{{getHotStyle(hot).separator}}{{hot.MaxHot}} {{'MIN'|translate}}</span></span>
                                                </div>
                                                <div class="timer">
                                                    <div class="middle">
                                                        <div class="progressBar">
                                                            <div class="upperProgressBar thin" style="background-color: gray;"></div>
                                                            <div *ngIf="!isEmpty(hot.MaxHot)" class="lowerProgressBar thin min" [style.width.%]="((hot.MaxHot * 100)/maxHOTConfig)"></div>
                                                            <div *ngIf="!isEmpty(hot.MinHot)" class="lowerProgressBar thin max" [style.width.%]="((hot.MinHot * 100)/maxHOTConfig)"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row row-center hotOptions backgroundColour">
                                        <div class="col"></div>
                                        <div class="col holdoverTaxiTimePrediction" *ngIf="('DispatchHoldoverTaxiTimePrediction'|hasPermission) && hotResponse !== null && holdoverTaxiTimeMessage != null">
                                            <div class="holdoverTaxiTimeLines" *ngIf="hasTimer">
                                                <div *ngIf="holdoverTaxiTimeLower" class="holdoverTaxiTimeLower" [style.width]="(20 + ((holdoverTaxiTimeLower * 60)/maxHOTConfig)) + '%'"></div>
                                                <div *ngIf="holdoverTaxiTimeUpper" class="holdoverTaxiTimeUpper" [style.width]="(20 + ((holdoverTaxiTimeUpper * 60)/maxHOTConfig)) + '%'"></div>
                                            </div>
                                            <div class="holdoverTaxiTimeLabels" *ngIf="hasTimer">
                                                <div *ngIf="holdoverTaxiTimeLower" class="holdoverTaxiTimeLower" [style.margin-left]="(20 + ((holdoverTaxiTimeLower * 60)/maxHOTConfig)) + '%'"><span class="backgroundColour">{{Math.round(holdoverTaxiTimeLower)}}</span></div>
                                                <div *ngIf="holdoverTaxiTimeUpper" class="holdoverTaxiTimeUpper" [style.margin-left]="Math.max(24 + ((holdoverTaxiTimeLower * 60)/maxHOTConfig), 20 + ((holdoverTaxiTimeUpper * 60)/maxHOTConfig)) + '%'"><span class="backgroundColour">{{Math.round(holdoverTaxiTimeUpper)}}</span></div>
                                            </div>
                                            <div class="holdoverTaxiTimeMessage">
                                                {{holdoverTaxiTimeMessage}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col" *ngIf="showMetarRefHot && hotResponse?.Type !== 'METAR' && !manualEntryMode">
                                <div class="fluidList">
                                    <div class="row">
                                        <div class="col col-60">
                                            <h3 class="center-text">METAR</h3>
                                        </div>
                                    </div>
                                    <div class="row" *ngFor="let hot of hotResponse?.HotResponseHots|orderBy:['FluidOrder', 'FluidType', 'FluidName', '-SortableDilution']">

                                        <div class="col col-60 hot" style="margin-left: 10px;">
                                            <div class="holdoverTimeMessage backgroundColour" *ngIf="!isEmpty(hot.ReferenceMetarMessage)">
                                                <span style="white-space: pre-wrap;">{{hot.ReferenceMetarMessage}}</span>
                                            </div>
                                            <div *ngIf="!isEmpty(hot.ReferenceMetarMinHot)">
                                                <div style="margin: 0px 0px 9px 15px; overflow: hidden;">
                                                    <span [style.display]="getRefMetarStyle(hot).min.display" 
                                                          [style.width.%]="getRefMetarStyle(hot).min.width" 
                                                          [style.text-align]="getRefMetarStyle(hot).min['text-align']" 
                                                          [style.float]="getRefMetarStyle(hot).min.float">
                                                        <span class="backgroundColour">{{hot.ReferenceMetarMinHot}} {{'MIN'|translate}}</span>
                                                    </span>
                                                    <span [style.display]="getRefMetarStyle(hot).max.display" 
                                                          [style.width.%]="getRefMetarStyle(hot).max.width" 
                                                          [style.text-align]="getRefMetarStyle(hot).max['text-align']" 
                                                          [style.float]="getRefMetarStyle(hot).max.float" 
                                                          *ngIf="!isEmpty(hot.ReferenceMetarMaxHot)">
                                                        <span class="backgroundColour">{{getRefMetarStyle(hot).separator}}{{hot.ReferenceMetarMaxHot}} {{'MIN'|translate}}</span>
                                                    </span>
                                                </div>
                                                
                                                <div class="timer">
                                                    <div class="middle">
                                                        <div class="progressBar">
                                                            <div class="upperProgressBar thin" style="background-color: gray;"></div>
                                                            <div *ngIf="!isEmpty(hot.ReferenceMetarMaxHot)" class="lowerProgressBar thin min" [style.width.%]="((hot.ReferenceMetarMaxHot * 100)/maxRefMetarConfig)"></div>
                                                            <div *ngIf="!isEmpty(hot.ReferenceMetarMinHot)" class="lowerProgressBar thin max" [style.width.%]="((hot.ReferenceMetarMinHot * 100)/maxRefMetarConfig)"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
					</form>
				</portlet>

				<portlet [title]="'Throughput'" [name]="'throughput-graph'" class="throughput-graph" *ngIf="('DispatchThroughputGraph'|hasPermission) && selectedAirport?.DispatchThroughputGraph && throughputSamples">
					<label>
						<span class="caption" style="margin-right: 5px;">{{'Date ending in' | translate}}</span>
						<input date-time [options]="throughputDatetimePickerOptions" [(ngModel)]="throughputQuery.Datetime" name="throughputQuery.Datetime" (ngModelChange)="throughputQueryChanged($event)" /> {{$root.currentUser.UseLocalTime ? 'LT' : 'Z'}}
					</label>
					<throughput-graph [samples]="throughputSamples" [count]="throughputSamplesCount" [options]="throughputGraphOptions"></throughput-graph>
				</portlet>

				<portlet [title]="'HOT graph'" [name]="'hot-graph'" class="hot-graph" *ngIf="('DispatchHotGraph'|hasPermission) && !manualEntryMode && selectedAirport && graphHots != null && graphHots.size > 0 && selectedAirportFluids?.length > 0">
					<hot-graph [data]="graphHots" [fluids]="selectedAirportFluids" [count]="graphHotsCount" [useFluidColors]="true" [options]="hotGraphOptions"></hot-graph>
				</portlet>

                <portlet [title]="'HOT request history'" [name]="'hot-request-history'" class="hot-request-history" *ngIf="('DispatchHotRequestHistory'|hasPermission) && hotRequests">
                    <div>
                        <label>
                            <span class="caption">{{'Request type'|translate}}</span>
                            <multiselect name="hotRequestQuery.HotRequestType" [(ngModel)]="hotRequestQuery.HotRequestType" (ngModelChange)="hotRequestQueryChanged($event)">
                                <multiselect-option *ngFor="let hotRequestType of hotRequestTypes|keys" [value]="hotRequestType" [name]="hotRequestTypes[hotRequestType]"></multiselect-option>
                            </multiselect>
                        </label>
                    </div>
                    <div *ngIf="showShiftSelection && ('DeskSelection'|hasPermission)" style="padding: 5px 0">
                        <label>
                            <input type="checkbox" [checked]="showActiveFlights" (change)="toggleActiveFlights($event)">
                            Active Flights
                        </label>
                    </div>
                    <hot-request-history [data]="hotRequests" [fluids]="selectedAirportFluids"></hot-request-history>
                </portlet>

                <portlet [title]="'Moderate Precipitation Nowcast'" *ngIf="('NowcastGraph'|hasPermission) && selectedAirport && selectedAirport.UseNowcast">
                    <nowcast-graph [data]="nowcastData$ | async"></nowcast-graph>
                </portlet>
            </portlets>
		</div>
    </div>
</div>
