<div id="login-view" class="login-container">
    <div class="login-backdrop"></div>
    <form class="login" (ngSubmit)="login()" #form="ngForm" [hidden]="!showLoginForm">
        <img class="logo" src="/images/surewx_portals_logo.svg">
        <div class="form-group">
            <label>{{'Email'|translate}}</label>
            <input id="email" type="email" [required]="password != null || loginAttempted" name="username" [(ngModel)]="username" placeholder="test@example.com">
        </div>
        <div class="form-group">
            <label>{{'Password'|translate}}</label>
            <input id="password" type="password" [required]="username != null || loginAttempted" name="password" [(ngModel)]="password" placeholder="Password">
            <a id="forgotten-password" *ngIf="$root.currentClient.PortalPasswordResetAllowed" routerLink="/forgotPassword">{{'Forgotten password?'|translate}}</a>
            <div *ngIf="$root.currentClient.AutoregistrationAllowed">
                <a id="register"  routerLink="/register">{{"Don't have an account? Register"|translate}}</a>
            </div>
        </div>
        <input type="submit" class="btn btn-info" [disabled]="!form.form.valid" value="{{'Log in'|translate}}" />
        <div *ngIf="loginError" class="alert alert-error">
            <span>{{'Sorry, that login/password does not match our records.'|translate}}</span>
        </div>
        <div class="privacy-policy">
            <span>&copy; SureWx 2023 <a [routerLink]="['/privacy']">Privacy &amp; Terms</a></span>
        </div>
    </form>
</div>

