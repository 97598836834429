import { Component, OnInit, NgZone, ViewChildren, QueryList, ElementRef } from '@angular/core';
import moment from 'moment';
import jQuery from 'jquery';
import { BusyIndicatorService, IResourceItemList, IResourceItemMap } from 'swx.front-end-lib';
import { ApiService, Fluid, HotResponseHot } from '../shared/Api.service';
import { RootScope } from '../shared/RootScope.service';
import { FluidSortPipe } from '../shared/FluidSort.pipe';
import {GraphHots} from "../hotGraph/HotGraph.component";

@Component({
    templateUrl: 'Hots.component.html',
    styleUrls: ['./Hots.component.scss']
})
export class HotsComponent implements OnInit {
    moment = moment;
    selectedAirport = '';
    airportCodes = [];
    preferredAirports = [];
    airports = [];
    query: any = {};
    hotHistoryDatePickerOptions: any;
    hotRequestHistoryDatePickerOptions: any;
    latestHot: any = null;
    showHistory = false;
    graphHots: IResourceItemMap<GraphHots>;
    graphHotsCount: any;
    fluids: IResourceItemList<Fluid>;
    selectedAirportFluids: Array<Fluid>;
    hotRequests: any;
    currentAirportCode;
    showAutocompleteList: boolean;
    latestAirport;
    Datetime = "";
    hotRequestQuery = {
        Datetime: "",
        HotRequestType: []
    };
    hotRequestTypes = {
        "API": "API",
        "Portal": "Portal",
        "ACARS": "ACARS",
        "MobileAPI": "MOBILE API",
        "XML": "XML",
    };
    historyHots;
    dashboards;
    hotGraphShown: boolean;
    hotRequestHistoryShown: boolean;
    @ViewChildren('airportCode') airportCodeElementList: QueryList<ElementRef>;

    constructor(
        private ngZone: NgZone,
        private api: ApiService,
        public busyIndicatorService: BusyIndicatorService,
        public $root: RootScope,
    ) {
    }

    ngOnInit(): void {
        this.fluids = this.api.Fluid.query();

        this.hotHistoryDatePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
            onClose: selectedDate => {
                this.getHistoryHots(selectedDate);
            }
        };
        
        this.hotRequestHistoryDatePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        this.query.ClientId = this.$root.currentUser.ClientId;

        this.api.ClientAirport.query().$promise.then(airports => {
            this.airports = airports;

            this.query.AirportId = [];
            var preferredAirports = this.airports.filter(a => a.Preferred);

            preferredAirports.forEach((airport) => {
                this.query.AirportId.push(airport.Id);
            });

            setTimeout(() => {
                this.setAutocompleteAirports();
            }, 500);

            if (this.airports.length === 1) {
                var airportCode = this.$root.currentUser.UseIataCode ? this.airports[0].IATACode : this.airports[0].ICAOCode;
                this.latestAirport = airportCode;
                this.getRecentHots(airportCode);
            }
        });
    }
    
    setAutocompleteAirports() {
        this.airports.forEach((item) => {
            var option = {
                id: this.$root.currentUser.UseIataCode ? item.IATACode : item.ICAOCode,
                label: this.$root.currentUser.UseIataCode ? (item.IATACode + '/' + item.ICAOCode) : (item.ICAOCode + '/' + item.IATACode)
            };

            this.airportCodes.push(option);

            if (item.Preferred) {
                this.preferredAirports.push(option);
            }
        });

        this.airportCodeElementList.changes.subscribe(changes => {
            var airportCode = changes.last as ElementRef;
            
            // jquery autocomplete
            jQuery(airportCode.nativeElement).autocomplete({
                source: this.airportCodes
            });

            jQuery(airportCode.nativeElement).on('autocompletechange', (event, ui) => {
                if (ui.item) {
                    this.selectedAirport = ui.item.id;
                }
            });

            jQuery(airportCode.nativeElement).on('keyup', (e) => {
                if (e.keyCode === 13) {
                    this.getAirportHots(airportCode.nativeElement.value);
                }
            });
        });
        
        this.showAutocompleteList = this.preferredAirports.length < this.airports.length;
    }
 
    clear() {
        this.latestHot = null;
        this.showHistory = false;
        this.hotGraphShown = false;
        this.hotRequestHistoryShown = false;
        this.graphHots = null;
        this.hotRequests = null;
    }

    getAirportHots(airportCode) {
        this.clear();

        var airport = this.airports.find(item => {
            return item.IATACode.toLowerCase() === airportCode.toLowerCase()
                || item.IATACode.toLowerCase() === airportCode.toLowerCase()
                || (this.$root.currentUser.UseIataCode ? (item.IATACode + '/' + item.ICAOCode) : (item.ICAOCode + '/' + item.IATACode)).toLowerCase() === airportCode.toLowerCase();
        });

        if (airport) {
            airportCode = this.$root.currentUser.UseIataCode ? airport.IATACode : airport.ICAOCode;
        }
                
        this.latestAirport = airportCode;
        this.getRecentHots(airportCode);
    }

    toggleHistory() {
        this.showHistory = !this.showHistory;
        this.Datetime = "";

        if (this.showHistory) {
            this.getHistoryHots();
        }
    }

    getHistoryHots(selectedDate?: string) {
        this.showHistory = true;

        this.query.Count = 144; //
        this.query.Datetime = this.Datetime;
        this.query.PortalUserId = this.$root.currentUser.UserId;

        this.historyHots = this.api.ClientPortalHotHistory.query(this.query);
    }

    isDashboardEmpty() {
        return typeof this.dashboards === "undefined" || (JSON.stringify(this.dashboards) === "{}");
    }
    
    showHotGraph() {
        this.graphHotsCount = this.$root.currentClient.PortalHotGraphDuration / this.$root.currentClient.PortalHotGraphAveragingPeriod;

        this.graphHots = null;
        this.fluids.$promise.then(() => {
            this.graphHots = this.api.ClientPortalHotGraphHot.queryMap<GraphHots>({
                ClientId: this.query.ClientId,
                AirportCode: this.query.AirportCode,
                Count: this.graphHotsCount,
                Interval: this.$root.currentClient.PortalHotGraphAveragingPeriod,
                PortalUserId: this.$root.currentUser.UserId,
            });
        });
    }

    toggleHotGraph() {
        this.hotGraphShown = !this.hotGraphShown;

        if (this.hotGraphShown) {
            this.showHotGraph();
        }
    }

    hotRequestQueryChanged() {
        this.hotRequests = [];
        this.hotRequests = this.api.ClientPortalHotRequest.query(Object.assign(this.hotRequestQuery, {
            AirportCode: this.query.AirportCode
        }));
    }

    toggleHotRequestHistory() {
        this.hotRequestHistoryShown = !this.hotRequestHistoryShown;

        if (this.hotRequestHistoryShown) {
            this.hotRequestQueryChanged();
        }
    }

    getRecentHots(airportCode) {
        if (typeof airportCode === "undefined" || airportCode === "") {
            alert("Airport selection is required");
            return false;
        }

        this.query.PortalUserId = this.$root.currentUser.UserId;
        this.currentAirportCode = airportCode;
        this.query.AirportCode = airportCode;
        this.query.Count = 1;
        this.query.Datetime = null;

        this.api.ClientPortalHot.queryObject(this.query).$promise.then(result => {
            this.latestHot = null;

            setTimeout(() => {
                this.latestHot = result;

                this.fluids.$promise.then(() => {
                    this.selectedAirportFluids = FluidSortPipe.sort(this.fluids.filter(f => f.Airports.some(a => a === result.AirportId as any)));
                });
            });
        });

        if (this.showHistory) {
            this.getHistoryHots();
        }

        if (this.hotGraphShown) {
            this.showHotGraph();
        }

        if (this.hotRequestHistoryShown) {
            this.hotRequestQueryChanged();
        }

        return true;
    }

    findHot(hotResponseHots: Array<HotResponseHot>, fluid: Fluid): HotResponseHot|undefined {
        return (hotResponseHots || []).find(h => h.FluidId === fluid.Id);
    }
    
    hasNoError(historyHot) {
        return historyHot.Error == '';
    }
    
    // helper: message prompt
    getMessagePrompt(): string {
        return this.showAutocompleteList ?
            'Select an airport by using one of the buttons or by entering the airport' : 
            'Select an airport'
    }
    
    // helper: get code for airport
    getAirportCode(airport): string {
        return this.$root.currentUser.UseIataCode ? airport.IATACode : airport.ICAOCode
    }
}
