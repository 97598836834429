import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { AuthenticationService } from 'swx.front-end-lib';
import { CLIENT_CODE } from '../shared/ClientCode.const';

@Injectable()
export class HasPermissionService {
    currentUser;

    constructor(
        private authentication: AuthenticationService,
    ) {
        this.authentication.getCurrentUser(CLIENT_CODE)
            .then(user => {
                this.currentUser = user;
            });

        this.authentication.userChange
            .subscribe(user => {
                this.currentUser = user;
            });
    }

    hasPermission(permission: string) {
        if (this.currentUser) {
            const selected = (this.currentUser.Permissions || '').split(', ');
            return selected.indexOf(permission) > -1;
        }
        return false;
    }
}

@Pipe({
    name: 'hasPermission',
    pure: false
})
export class HasPermissionPipe implements PipeTransform {
    constructor(
        private hasPermissionService: HasPermissionService,
    ) {
    }

    transform(permission: string): boolean {
        return this.hasPermissionService.hasPermission(permission);
    }
}
