<page-title></page-title>
<div class="deicingPortalSearch" [style.display]="airports?.length > 1 ? 'block' : 'none'">
    <span class="mdi mdi-magnify"></span>
    <input type="text" name="airportSearch" [(ngModel)]="airportSearch" />
    <div class="button mdi mdi-chevron-down"></div>
</div>
<div class="deicingPortal contentWrapper">
    <div class="toolbar airports" *ngIf="airports?.length > 1">
        <div *ngFor="let airport of (airports|filter:{Preferred:true}|orderBy:['ICAOCode']).concat(adHocAirports)"
             class="airport"
             [class.selected]="selectedAirport?.Id === airport.Id"
             (click)="selectAirport(airport)">
            <div class="airportCodeWrapper">
                <span class="airportCode">{{$root.currentUser.UseIataCode ? airport.IATACode : airport.ICAOCode}}</span>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="topPane">
            <div class="details" *ngIf="selectedAirport">
                <div class="selectedAirport">
                    <div class="airportCodeWrapper">
                        <span class="mdi mdi-map-marker" [style.color]="'#aaaaaa'"></span>
                        <span class="airportCode">{{$root.currentUser.UseIataCode ? selectedAirport.IATACode : selectedAirport.ICAOCode}}</span>
                        <button type="button" class="refresh" (click)="refreshClick()"><span class="mdi mdi-refresh"></span></button>
                        <button type="button" class="play" *ngIf="refreshInterval == null && playInterval == null" (click)="play()"><span class="mdi mdi-play"></span></button>
                        <button type="button" class="pause" *ngIf="refreshInterval != null || playInterval != null" (click)="pause()"><span class="mdi mdi-pause"></span></button>
                        <div class="requestTime" *ngIf="refreshInterval != null">
                            <span class="mdi mdi-clock"></span>
                            {{$root.currentUser.UseLocalTime && selectedAirport.TimeZone ? (moment.utc(dateTime).tz(selectedAirport.TimeZone).format($root.dateFormat + ' HH:mm') + ' LT') : (moment.utc(dateTime).format($root.dateFormat + ' HH:mm') + ' Z')}}
                        </div>
                        <div class="requestTimeInput" *ngIf="refreshInterval == null">
                            <span class="mdi mdi-clock"></span>
                            <input date required [options]="datePickerOptions" [(ngModel)]="dateString" (ngModelChange)="updateDate()" /> {{dateTime.format('HH:mm')}} Z
                            <div class="time">00:00 <input type="range" min="0" max="1439" [(ngModel)]="dateTimeTime" (ngModelChange)="updateTime()" /> 23:59</div>
                        </div>
                    </div>
                </div>
                <div class="flightCategories" *ngIf="categorizedFlights != null">
                    <div class="flightCategory" *ngFor="let flightCategory of flightCategories">
                        <div class="title" [style.backgroundColor]="flightCategory.backgroundColor" [style.color]="flightCategory.color">{{flightCategory.title}}</div>
                        <div class="flights">
                            <div class="flightsWrapper">
                                <div class="flight" *ngFor="let flight of categorizedFlights[flightCategory.id].flights|orderBy:'-laneTime'">
                                    <div class="left">
                                        <span class="mdi mdi-airplane"></span>
                                        <span class="laneTime" *ngIf="flight.laneTime != null" [class.overtime]="flight.laneTime > 60">{{Math.round(flight.laneTime)}} min</span>
                                    </div>
                                    <div class="right">
                                        <span class="flightNumber">{{flight.FlightIdentifierRaw}}</span>
                                        <span class="equipmentType">{{flight.EquipmentTypeIdentifier}}</span>
                                        <span class="registrationNumber">{{flight.RegistrationNumber}}</span>
                                        <span class="deicingLocation" *ngIf="(flightCategory.id === 'taxiIn' || flightCategory.id === 'parkIn' || flightCategory.id === 'ctm') && flight.DeicingLocation">{{flight.DeicingLocation.replace('BAY BAY', 'BAY')}}</span>
                                        <!--
                                    <table>
                                        <tr *ngIf="flight.OffBlockTime"><td>Out: </td><td>{{moment.utc(flight.OffBlockTime).format('HH:mm')}}</td></tr>
                                        <tr *ngIf="flight.TaxiInTime"><td>Taxi in: </td><td>{{moment.utc(flight.TaxiInTime).format('HH:mm')}}</td></tr>
                                        <tr *ngIf="flight.ParkInTime"><td>Park in: </td><td>{{moment.utc(flight.ParkInTime).format('HH:mm')}}</td></tr>
                                        <tr *ngIf="flight.IcemanAcceptInTime"><td>Ice in: </td><td>{{moment.utc(flight.IcemanAcceptInTime).format('HH:mm')}}</td></tr>
                                        <tr *ngIf="flight.CTMCallTime"><td>CTM: </td><td>{{moment.utc(flight.CTMCallTime).format('HH:mm')}}</td></tr>
                                        <tr *ngIf="flight.IcehouseAcceptOutTime"><td>Ice out: </td><td>{{moment.utc(flight.IcehouseAcceptOutTime).format('HH:mm')}}</td></tr>
                                        <tr *ngIf="flight.TaxiOutTime"><td>Taxi out: </td><td>{{moment.utc(flight.TaxiOutTime).format('HH:mm')}}</td></tr>
                                        <tr *ngIf="flight.WheelsUpTime"><td>Off: </td><td>{{moment.utc(flight.WheelsUpTime).format('HH:mm')}}</td></tr>
                                    </table>
                                    -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary">
                            <div class="average" *ngIf="categorizedFlights[flightCategory.id].average > 0">
                                <span *ngIf="flightCategory.id === 'parkIn'">
                                    Estimated spray time:<br>
                                    Narrow: {{getNarrowPredictedTime(categorizedFlights[flightCategory.id].average)}} min
                                    Wide: {{getWidePredictedTime(categorizedFlights[flightCategory.id].average)}} min
                                </span><br />
                                Avg. {{Math.round(categorizedFlights[flightCategory.id].average * 10) / 10}} minutes
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="horizontalBorder"></div>
        <div class="bottomPane">
            <div #chartEl style="width: 100%; height: 100%;"></div>
        </div>
    </div>
</div>
