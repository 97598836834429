<ng-container *ngIf="airport.IsClear">
    <span class="weatherIcon" *ngIf="!airport.IsClearSky" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0003_white_cloud.svg');"></span>
    <span class="weatherIcon" *ngIf="airport.IsClearSky && airport.IsNightTime" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0008_clear_sky_night.svg');"></span>
    <span class="weatherIcon" *ngIf="airport.IsClearSky && !airport.IsNightTime" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0001_sunny.svg');"></span>
</ng-container>
<ng-container *ngIf="airport.HotResponse.Type === 'METAR' && !airport.IsClear && airport.WeatherTypeNameResult" [ngSwitch]="airport.WeatherTypeNameResult">
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0019_cloudy_with_light_snow.svg');" *ngSwitchCase="'1SN'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0019_cloudy_with_light_snow.svg');" *ngSwitchCase="'-SN'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0020_cloudy_with_heavy_snow.svg');" *ngSwitchCase="'SN'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0052_extreme_snow.svg');" *ngSwitchCase="'+SN'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0017_cloudy_with_light_rain.svg');" *ngSwitchCase="'-RA'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0017_cloudy_with_light_rain.svg');" *ngSwitchCase="'RA'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0018_cloudy_with_heavy_rain.svg');" *ngSwitchCase="'+RA'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0048_drizzle.svg');" *ngSwitchCase="'-DZ'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0081_heavy_drizzle.svg');" *ngSwitchCase="'DZ'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0081_heavy_drizzle.svg');" *ngSwitchCase="'+DZ'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0049_freezing_drizzle.svg');" *ngSwitchCase="'-FD'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0049_freezing_drizzle.svg');" *ngSwitchCase="'FD'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0049_freezing_drizzle.svg');" *ngSwitchCase="'+FD'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0050_freezing_rain.svg');" *ngSwitchCase="'-FR'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0050_freezing_rain.svg');" *ngSwitchCase="'FR'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0050_freezing_rain.svg');" *ngSwitchCase="'+FR'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0047_freezing_fog.svg');" *ngSwitchCase="'FF'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0047_freezing_fog.svg');" *ngSwitchCase="'FZBR'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0007_fog.svg');" *ngSwitchCase="'FG'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0007_fog.svg');" *ngSwitchCase="'BR'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'-IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'1IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'2IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'3IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'4IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'5IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'6IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'7IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'8IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'9IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'10IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'11IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'+IP'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0019_cloudy_with_light_snow.svg');" *ngSwitchCase="'SNFG'"></span>
    <span *ngSwitchDefault>
        {{airport.WeatherTypeNameResult}}
    </span>
</ng-container>
<ng-container *ngIf="airport.HotResponse.Type === 'LWE' && !airport.IsClear && airport.HotResponse.WeatherType" [ngSwitch]="airport.HotResponse.WeatherType">
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0050_freezing_rain.svg');" *ngSwitchCase="'SLD'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0050_freezing_rain.svg');" *ngSwitchCase="'FZDZ'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0050_freezing_rain.svg');" *ngSwitchCase="'FZRA'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0020_cloudy_with_heavy_snow.svg');" *ngSwitchCase="'SN'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0017_cloudy_with_light_rain.svg');" *ngSwitchCase="'RA'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'PL'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0022_cloudy_with_light_hail.svg');" *ngSwitchCase="'GR'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0047_freezing_fog.svg');" *ngSwitchCase="'FZFG'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0047_freezing_fog.svg');" *ngSwitchCase="'FZBR'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0007_fog.svg');" *ngSwitchCase="'FG'"></span>
    <span class="weatherIcon withPrecip" style="background-image: url('/images/MAm-WeatherIcons-MS02e/wsymbol_0007_fog.svg');" *ngSwitchCase="'BR'"></span>
    <span *ngSwitchDefault>
        {{airport.HotResponse.WeatherTypeCode}}
    </span>
</ng-container>