import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params} from '@angular/router';
import { RootScope } from '../shared/RootScope.service';

@Component({
    templateUrl: 'Saml2Error.component.html'
})
export class Saml2ErrorComponent implements OnInit {
    message: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        public $root: RootScope,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.message = params['message'];
        });
    }
}