import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { RootScope } from '../shared/RootScope.service';

@Pipe({
    name: 'formatTemperature',
    pure: false
})
export class FormatTemperaturePipe implements PipeTransform {
    constructor(
        private decimalPipe: DecimalPipe,
        public $root: RootScope,
    ) {
    }

    static celsiusToFahrenheit(celsiusTemp: number): number {
        return celsiusTemp * 1.8 + 32;
    }

    static fahrenheitToCelsius(fahrenheitTemp: number): number {
        return (fahrenheitTemp - 32) * 5/9;
    }

    transform(temperature, hotType, decimalPlaces = 2): string {
        if (this.$root.currentUser.UseFahrenheit) {
            temperature = FormatTemperaturePipe.celsiusToFahrenheit(temperature);
            if (hotType === 'METAR') {
                temperature = Math.floor(temperature);
            }
        }
        return temperature === null ? '' : this.decimalPipe.transform(Math.floor(temperature * 100) / 100, `1.${decimalPlaces}-${decimalPlaces}`) + (this.$root.currentUser.UseFahrenheit ? ' \u00B0F' : ' \u00B0C');
    }
}
