import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { RootScope } from '../shared/RootScope.service';
import { CLIENT_CODE } from "./ClientCode.const";
import { AuthenticationService } from 'swx.front-end-lib';
import { ApiService } from './Api.service';

@Injectable()
export class AuthGuard  {
    constructor(
        private router: Router,
        public $root: RootScope,
        private authentication: AuthenticationService,
        private api: ApiService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authentication.getCurrentUser(CLIENT_CODE)
            .then(() => true,
                () => {
                    this.$root.currentClient.$promise.then(() => {
                        if (this.$root.currentClient.PortalUseSaml2Authentication
                                && window.location.search.indexOf('skipsaml') === -1) {
                            this.samlLoginRedirect();
                        } else {
                            this.router.navigateByUrl('/login');
                        }
                    });

                    return false;
                });
    }

    samlLoginRedirect() {
        this.api.SamlLoginRequest.get(CLIENT_CODE).$promise.then(data => {
            var form = document.createElement("form");
            form.method = "POST";
            form.action = data.SingleSignOnServicePost;   
            form.target = "_top";

            var inputSAMLRequest = document.createElement("input");
            inputSAMLRequest.type = "hidden";
            inputSAMLRequest.name = "SAMLRequest";
            inputSAMLRequest.value = btoa(data.SAMLRequest);
            form.appendChild(inputSAMLRequest);  

            var inputRelayState = document.createElement("input");
            inputRelayState.type = "hidden";
            inputRelayState.name = "RelayState";
            form.appendChild(inputRelayState); 
                    
            document.body.appendChild(form);

            form.submit();
        });
    }

    samlLogoutRedirect() {
        this.api.SamlLogoutRequest.get(CLIENT_CODE).$promise.then(data => {
            this.authentication.clearCredentials();

            let form = document.createElement("form");
            form.target = "_top";
            document.body.appendChild(form);

            let inputSAMLRequest = document.createElement("input");
            inputSAMLRequest.type = "hidden";
            inputSAMLRequest.name = "SAMLRequest";
            form.appendChild(inputSAMLRequest);

            if (data.SingleLogoutServicePost) {
                form.method = "POST";
                form.action = data.SingleLogoutServicePost;

                inputSAMLRequest.value = btoa(data.SAMLRequest);

                let inputRelayState = document.createElement("input");
                inputRelayState.type = "hidden";
                inputRelayState.name = "RelayState";
                form.appendChild(inputRelayState);
            } else if (data.SingleLogoutServiceRedirect) {
                form.method = "GET";
                form.action = data.SingleLogoutServiceRedirect;

                inputSAMLRequest.value = data.SAMLRequest;
            }

            form.submit();
        });
    }
}
