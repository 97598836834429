import { Component, Input } from '@angular/core';
import moment from 'moment';
import { RootScope } from '../shared/RootScope.service';
import { Fluid, HotResponseHot } from '../shared/Api.service';


@Component({
    selector: 'hot-request-history',
    templateUrl: 'HotRequestHistory.component.html'
})
export class HotRequestHistoryComponent {
    moment = moment;
    @Input('data') hotRequests: any = {};
    @Input('fluids') fluids: Array<Fluid>;

    constructor(
        public $root: RootScope,
    ) {
    }

    findHot(hotResponseHots: Array<HotResponseHot>, fluid: Fluid): HotResponseHot|undefined {
        return (hotResponseHots || []).find(h => h.FluidId === fluid.Id);
    }
}
