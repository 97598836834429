<page-title></page-title>
<div class="noMargins contentWrapper">
    <div class="toolbar gridFilters">
        <a class="tab" (click)="switchTab('configurations')" [class.active]="tab == 'configurations'">{{'Configurations'|translate}}</a>
        <a class="tab" (click)="switchTab('users')" [class.active]="tab == 'users'" [class.invalid]="!ngUsersForm.form.valid">{{'Users'|translate}}</a>
        <a class="tab" (click)="switchTab('alerts')" [class.active]="tab == 'alerts'" [class.invalid]="!ngAlertsForm.form.valid">{{'Alerts'|translate}}</a>

        <label class="inline" *ngIf="tab == 'configurations'">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="searchQuery" name="searchQuery" />
        </label>
        <label class="inline" *ngIf="tab == 'configurations'">
            <input type="checkbox" [(ngModel)]="includeInactive" name="includeInactive" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <label *ngIf="tab == 'configurations'">
            <a *ngIf="'DispatchConfiguration'|hasPermission" routerLink="/dispatchAirportProfiles/new"><button type="button">{{'Add configuration'|translate}}</button></a>
        </label>
        <label *ngIf="tab == 'users'">
            <input type="button" (click)="saveUsers()" [disabled]="!ngUsersForm.form.valid || ngUsersForm.form.pristine" value="{{'Save'|translate}}" />
        </label>
        <label *ngIf="tab == 'alerts'">
            <input type="button" (click)="saveAlerts()" [disabled]="!ngAlertsForm.form.valid || ngAlertsForm.form.pristine" value="{{'Save'|translate}}" />
        </label>
    </div>
    <table class="table" [style.display]="tab == 'configurations' ? '' :'none'">
        <tr>
            <th sortable-column="item.Id">{{'#'|translate}}</th>
            <th sortable-column="item.Name">{{'Name'|translate}}</th>
            <th sortable-column="(item.Default ? 'Yes' : 'No')|translate">{{'Is default configuration?'|translate}}</th>
            <th sortable-column="(item.Active ? 'Yes' : 'No')|translate">{{'Active?'|translate}}</th>
            <th sortable-column="item.CreatedDate.format($root.dateTimeFormat)">{{'Created date'|translate}}</th>
            <th sortable-column="item.CreatedByUserName">{{'Created by'|translate}}</th>
            <th sortable-column="item.LastModified.format($root.dateTimeFormat)">{{'Modified date'|translate}}</th>
            <th sortable-column="item.LastModifiedByUserName">{{'Modified by'|translate}}</th>
            <th></th>
        </tr>
        <tr *ngFor="let item of items|filter: searchQuery|includeInactive:includeInactive">
            <td>{{item.Id}}</td>
            <td>{{item.Name}}</td>
            <td>{{(item.Default ? 'Yes' : 'No')|translate}}</td>
            <td>{{(item.Active ? 'Yes' : 'No')|translate}}</td>
            <td>{{moment.utc(item.CreatedDate).format($root.dateTimeFormat)}}</td>
            <td>{{item.CreatedByUserName}}</td>
            <td>{{moment.utc(item.LastModified).format($root.dateTimeFormat)}}</td>
            <td>{{item.LastModifiedByUserName}}</td>
            <td>
                <a routerLink="/dispatchAirportProfiles/{{item.Id}}" class="mdi mdi-pencil" title="{{'Edit'|translate}}"></a>
                <a *ngIf="'DispatchConfiguration'|hasPermission" (click)="delete(item)" class="mdi mdi-delete" title="{{'Delete'|translate}}"></a>
                <a *ngIf="'DispatchConfiguration'|hasPermission" routerLink="/dispatchAirportProfiles/new" [queryParams]="{ copy: item.Id }" class="mdi mdi-content-copy" title="{{'Copy'|translate}}"></a>
            </td>
        </tr>
    </table>
    <form #form #ngUsersForm="ngForm">
        <fieldset [disabled]="!('DispatchConfiguration'|hasPermission)" class="contentMargins form" [style.display]="tab == 'users' ? '' :'none'" ngModelGroup="usersForm">
            <table class="table">
                <tr>
                    <th sortable-column="item.Email">{{'User'|translate}}</th>
                    <th>{{'Configuration'|translate}}</th>
                </tr>
                <tr *ngFor="let mapping of mappings|orderBy:'Email'; let i=index">
                    <td>{{mapping.Email}}</td>
                    <td>
                        <select [(ngModel)]="mapping.DispatchAirportProfileId" name="DispatchAirportProfileId[{{i}}]" [selectedIndex]="-1">
                            <option [ngValue]="null">{{'None'|translate}}</option>
                            <option *ngFor="let item of items|orderBy:'Name'|filter:{Active:true}" [ngValue]="item.Id">
                                {{item.Name}}
                            </option>
                        </select>
                    </td>
                </tr>
            </table>
        </fieldset>
    </form>
    <form #form #ngAlertsForm="ngForm">
        <fieldset [disabled]="!('DispatchConfiguration'|hasPermission)" class="contentMargins form" [style.display]="tab == 'alerts' ? '' :'none'" ngModelGroup="alertsForm">
            <div class="items" style="width: 750px;">
                <sortable [options]="{ placeholder: 'placeholder item' }">
                    <sortable-item class="item" *ngFor="let hotCondition of hotConditions | orderBy:'Rank'; let i=index" [(ngModel)]="hotCondition.Rank" name="Rank-{{hotCondition.Id}}">
                        <label><span class="mdi mdi-adjust"></span></label>
                        <label style="width: 250px;">{{hotCondition.Name}}</label>
                        <label style="float: right; margin-top: 7px;" [for]="'ShowAlertWhenReached-' + hotCondition.Id">{{'Alert when reached'|translate}}</label>
                        <input style="float: right; margin: 8px 5px 0 20px;" type="checkbox" [(ngModel)]="hotCondition.ShowAlertWhenReached" name="ShowAlertWhenReached-{{hotCondition.Id}}" [id]="'ShowAlertWhenReached' + hotCondition.Id" />
                        <select-menu [placeholder]="'icon'" class="icons" [width]="100" style="float: right; margin: 0;" [(ngModel)]="hotCondition.Icon" name="Icon-{{hotCondition.Id}}">
                            <select-menu-option *ngFor="let icon of icons" [value]="icon.cssClass">
                                {{icon.char == null ? 'None' : icon.char}}
                            </select-menu-option>
                        </select-menu>
                        <label style="float: right; margin: 1px;"><input type="text" style="width: 100px;" placeholder="{{'color'|translate}}" color-picker [(ngModel)]="hotCondition.Color" name="Color-{{hotCondition.Id}}" /></label>
                    </sortable-item>
                </sortable>
            </div>
        </fieldset>
    </form>
</div>
