<nav>
    <div *ngIf="$root.currentUser" class="current-user">
        <img *ngIf="$root.currentClient.ClientPortalLogo" [src]="getImageFor($root.currentClient.ClientPortalLogo)" [alt]="$root.currentClient.Name">
        <span>{{$root.currentUser.UserEmail}}</span>
    </div>
    <ul *ngFor="let group of itemGroups" class="menu-group" [ngStyle]="{backgroundColor: group.color}">
        <li *ngFor="let item of group.children" (click)="open($event, item, true)" routerLinkActive="active" class="menu-item">
            <div class="menu-title">
                <img style="align-self: baseline;" [src]="'images/wx.svg'" alt="swx">
                <a style="align-self: baseline;" [innerHTML]="item.title"></a>
                <span *ngIf="item.children" class="mdi mdi-chevron-right dropdown"></span>  
            </div>
            <ul *ngIf="item.children" class="menu-group sub-menu" 
                [ngStyle]="{backgroundColor: group.color}"
                [ngClass]="{open: item.isOpen}">
                <li class="close-btn"><span (click)="closeSubmenu($event)">close <i class="mdi mdi-close"></i></span></li>
                <li *ngFor="let child of item.children" (click)="open($event, child, false)" [routerLink]="child.route" routerLinkActive="active"> 
                    <img [src]="'images/wx.svg'" alt="swx">
                    <a [innerHTML]="child.title" ></a> 
                </li>
            </ul>
        </li>
    </ul>
    <div class="divider"></div>
    <ul>
        <li *ngIf="$root.currentUser && $root.currentClient.PortalPasswordChangeAllowed && !$root.currentClient.PortalOffSeason"> 
            <i class="mdi mdi-settings-outline"></i><a  (click)="openSettings($event)">{{'Settings'|translate}}</a>
        </li>
        <li *ngIf="'MobileAppDownload'|hasPermission" routerLinkActive="active" >
            <i class="mdi mdi-download"></i>
            <a [routerLink]="['/mobileAppDownload']">{{'Mobile app download'|translate}}</a>
        </li>
        <li *ngIf="$root.currentUser && $root.currentClient.ClientPortalDocumentId">
            <i class="mdi mdi-file-document"></i>
            <a (click)="downloadHeaderDocument()"><span>{{$root.currentClient.ClientPortalDocumentName}}</span></a> 
        </li>
        <li> <i class="mdi mdi-logout"></i><a (click)="onLogout()">{{'Logout'|translate}}</a></li>
    </ul>
</nav>
