/// <reference path="../../node_modules/@types/jquery.ui.datetimepicker/index.d.ts"/>
import { environment } from './environments/environment';
import { NgModule } from '@angular/core';
import {APP_BASE_HREF, DecimalPipe} from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SwxModule, GridModule, ExportApi, AuthModule, AuthenticationService } from 'swx.front-end-lib';
import { CLIENT_CODE } from './shared/ClientCode.const';
import { AuthGuard } from "./shared/AuthGuard.service";
import { RootScope } from './shared/RootScope.service';
import { DispatchMonitorService } from './shared/DispatchMonitor.service';
import { ApiService } from './shared/Api.service';
import { HasPermissionPipe, HasPermissionService } from './shared/HasPermission.pipe';
import { FormatTemperaturePipe } from './shared/FormatTemperature.pipe';
import { FluidSortPipe } from './shared/FluidSort.pipe';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/Login.component';
import { UnknownComponent } from './unknown/Unknown.component';
import { IndexComponent } from './index/Index.component';
import { HotGraphComponent } from './hotGraph/HotGraph.component';
import { HotRequestHistoryComponent } from './hotRequestHistory/HotRequestHistory.component';
import { HotResponseComponent } from './hotResponse/HotResponse.component';
import { HotsComponent } from './hots/Hots.component';
import { DashboardComponent } from './dashboard/Dashboard.component';
import { DispatchComponent } from './dispatch/Dispatch.component';
import { WeatherTypeDialogComponent } from './dispatch/WeatherTypeDialog.component';
import { IntensityDialogComponent } from './dispatch/IntensityDialog.component';
import { DispatchAirportComponent } from './dispatch/DispatchAirport.component';
import { DispatchAirportWeatherIconComponent } from './dispatch/DispatchAirportWeatherIcon.component';
import { DispatchAirportProfileListComponent } from './dispatchAirportProfile/DispatchAirportProfileList.component';
import { DispatchAirportProfileDetailComponent } from './dispatchAirportProfile/DispatchAirportProfileDetail.component';
import { ForgotPasswordComponent } from './login/ForgotPassword.component';
import { ResetPasswordComponent } from './login/ResetPassword.component';
import { UserProfileComponent } from './login/UserProfile.component';
import { RegisterComponent } from './login/Register.component';
import { ConfirmComponent } from './login/Confirm.component';
import { Saml2ErrorComponent } from './login/Saml2Error.component';
import { AnalyticsComponent } from './analytics/Analytics.component';
import { LWEGridComponent } from './lwe/LWEGrid.component';
import { ThroughputGraphComponent } from './throughputGraph/ThroughputGraph.component';
import { DeIcingPortalComponent } from './deicingPortal/DeIcingPortal.component';
import { NowcastGraphComponent } from './nowcastGraph/NowcastGraph.component';
import { DispatchAirportLegendComponent } from './dispatchAirportLegend/DispatchAirportLegend.component';
import { EngineCoverAlertGridComponent } from './engineCoverAlert/EngineCoverAlertGrid.component';
import { MobileAppDownloadComponent } from "./mobileAppDownload/MobileAppDownload.component";
import { AuthInterceptorService } from "swx.front-end-lib";
import {NavbarComponent} from "./navbar/Navbar.component";
import {MenuComponent} from "./navbar/Menu.component";
import {PrivacyComponent} from "./privacy/Privacy.component";
import { FedExShiftSelectionDialogComponent } from './fedExShift/FedExShiftSelectionDialog.component';
import {SearchBoxComponent} from "./searchBox/SearchBox.component";

enum NavColor {
    green = '#2B5257',
    brown = '#603A2D'
}

const appRoutes: Routes = [{ 
        path: 'login', 
        component: LoginComponent,
        data: {
            title: 'Login'
        }
    },
    { 
        path: 'forgotPassword', 
        component: ForgotPasswordComponent,
        data: {
            title: 'Forgot Password'
        }
    },
    { 
        path: 'resetPassword/:id', 
        component: ResetPasswordComponent,
        data: {
            title: 'Reset Password'
        }
    },
    { 
        path: 'confirm/:id', 
        component: ConfirmComponent,
        data: {
            title: 'Login'
        }
    },
    { 
        path: 'register', 
        component: RegisterComponent,
        data: {
            title: 'Register'
        }
    },
    { 
        path: 'privacy', 
        component: PrivacyComponent,
        data: {
            title: 'Privacy Policy'
        }
    },
    { 
        path: 'userProfile', 
        component: UserProfileComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Settings'
        }
    },
    { 
        path: 'saml2Error', 
        component: Saml2ErrorComponent 
    },
    { 
        path: 'hots', 
        component: HotsComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Legacy<strong>PORTAL</strong>'
        }
    },
    { 
        path: 'dispatch', 
        component: DispatchComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Dispatch<strong>PORTAL</strong>'
        }
    },
    { 
        path: 'dispatchAirportProfiles', 
        component: DispatchAirportProfileListComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Dispatch<strong>PORTAL</strong>'
        }
    },
    { 
        path: 'dispatchAirportProfiles/:id', 
        component: DispatchAirportProfileDetailComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Dispatch<strong>PORTAL</strong>'
        }
    },
    { 
        path: 'analytics/:id', 
        component: AnalyticsComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Analytics<strong>PORTAL</strong>'
        }
    },
    {
        path: 'warn/:id',
        component: AnalyticsComponent,
        canActivate: [AuthGuard],
        data: {
            color: NavColor.brown,
            title: 'Sure<strong>WARN</strong>'
        }
    },
    { 
        path: 'deicingPortal', 
        component: DeIcingPortalComponent, 
        canActivate: [AuthGuard], 
        data: {
            title: 'De-Icing<strong>PORTAL</strong>'
        }
    },
	{
        path: 'engineCover', 
        component: EngineCoverAlertGridComponent, 
        canActivate: [AuthGuard],
        data: {
            color: NavColor.brown,
            title: 'Sure<strong>WARN</strong>'
        }
    },
	{ 
        path: 'mobileAppDownload',
        component: MobileAppDownloadComponent, 
        canActivate: [AuthGuard], 
        title: 'App Download' ,
        data: {
            title: 'Mobile App Download'
        }
    },
    // currently ignored and not visible from the UI
    {
        path: 'lwe',
        component: LWEGridComponent,
        canActivate: [AuthGuard],
        title: 'LWEs',
        data: {
            title: 'LWEs'
        }
    },
    
    { 
        path: '', 
        component: IndexComponent, 
        canActivate: [AuthGuard] 
    },
    { 
        path: '**', 
        component: UnknownComponent 
    }
];

@NgModule({ declarations: [
        HasPermissionPipe,
        FormatTemperaturePipe,
        FluidSortPipe,
        AppComponent,
        LoginComponent,
        UnknownComponent,
        IndexComponent,
        HotGraphComponent,
        HotRequestHistoryComponent,
        HotResponseComponent,
        HotsComponent,
        DashboardComponent,
        DispatchComponent,
        WeatherTypeDialogComponent,
        IntensityDialogComponent,
        DispatchAirportComponent,
        DispatchAirportWeatherIconComponent,
        DispatchAirportProfileListComponent,
        DispatchAirportProfileDetailComponent,
        AnalyticsComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        UserProfileComponent,
        RegisterComponent,
        ConfirmComponent,
        Saml2ErrorComponent,
        LWEGridComponent,
        ThroughputGraphComponent,
        DeIcingPortalComponent,
        NowcastGraphComponent,
        DispatchAirportLegendComponent,
        EngineCoverAlertGridComponent,
        MobileAppDownloadComponent,
        NavbarComponent,
        MenuComponent,
        PrivacyComponent,
        FedExShiftSelectionDialogComponent,
        SearchBoxComponent,
    ],
    bootstrap: [
        AppComponent
    ], imports: [BrowserModule,
        RouterModule.forRoot(appRoutes),
        FormsModule,
        GridModule,
        SwxModule.forRoot(environment),
        AuthModule.forRoot({
            tokenUrl: `${environment.apiUrl}/oauth/access_token`,
            userUrl: `${environment.apiUrl}/user/me`,
            storageKey: 'access_token',
        })], providers: [
        { provide: APP_BASE_HREF, useValue: '/' + CLIENT_CODE },
        DecimalPipe,
        HasPermissionService,
        RootScope,
        AuthenticationService,
        AuthGuard,
        DispatchMonitorService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {
            provide: ExportApi,
            useClass: ApiService,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
