<page-title></page-title>
<div class="contentWrapper">
    <article class="alignCenter">
        <div *ngIf="error">
            <div class="alert alert-error">
                <span>{{'Your search did not return any results.'|translate}} <a routerLink="/login">{{'Click here'|translate}}</a> {{'to try again.'|translate}}</span>
            </div>
        </div>
        <div *ngIf="!error">
            <div *ngIf="sent">
                <div class="alert alert-success">
                    <span>{{'Your password has been changed.'|translate}} <a routerLink="/login">{{'Click here'|translate}}</a> {{'to log in.'|translate}}</span>
                </div>
            </div>
            <div *ngIf="!sent">
                <section class="login center-block" *ngIf="!error">
                    <form class="form" #form="ngForm" (submit)="submit()">
                        <fieldset>
                            <legend>{{'Reset password'|translate}}</legend>
                            <h4 style="margin-top: 0;">{{'Please enter your new password'|translate}}</h4>
                            <br />
                            <div>
                                <div class="form-group">
                                    <label>{{'New password'}}</label>
                                    <input type="password" required [(ngModel)]="password" name="password" minlength="6" />
                                </div>
                                <div class="form-group">
                                    <label>{{'Repeat password'}}</label>
                                    <input type="password" required [(ngModel)]="repeatPassword" name="repeatPassword" [password-match]="password" />
                                </div>
                                <br />
                                <input type="submit" [disabled]="!form.form.valid" value="{{'Change password'|translate}}" />
                            </div>
                        </fieldset>
                    </form>
                </section>
            </div>
        </div>
    </article>
</div>
