<div class="alignCenter contentWrapper">
    <br />
    <br />
    <br />
    <div *ngIf="preferencesSent">
        <div class="alert alert-success">
            <span>{{'Your preferences have been updated.'|translate}}</span>
        </div>
        <br />
        <br />
    </div>
    <h3>{{'Preferences'|translate}}</h3>
    <br />
    <form class="form" #preferencesForm="ngForm" (submit)="savePreferences()">
        <fieldset>
            <div>
                <label>
                    <input type="checkbox" [(ngModel)]="$root.currentUser.UseIataCode" name="$root.currentUser.UseIataCode">
                    {{'Use IATA code for airport and airline codes'|translate}}
                </label>
                <label>
                    <input type="checkbox" [(ngModel)]="$root.currentUser.UseLocalTime" name="$root.currentUser.UseLocalTime">
                    {{'Use local time'|translate}}
                </label>
                <label>
                    <input type="checkbox" [(ngModel)]="$root.currentUser.UseFahrenheit" name="$root.currentUser.UseFahrenheit">
                    {{'Use Fahrenheit'|translate}}
                </label>
                <input type="submit" class="btn btn-info" [disabled]="!preferencesForm.form.valid" value="{{'Save'|translate}}" />
            </div>
        </fieldset>
    </form>
    <br />
    <h3>{{'Update password'|translate}}</h3>
    <br />
    <form class="form" #changePasswordForm="ngForm" (submit)="changePassword()">
        <fieldset *ngIf="!$root.currentClient.PortalUseSaml2Authentication">
            <div *ngIf="error">
                <div class="alert alert-error">
                    <span>{{'Old password isn\'t valid.'|translate}}</span>
                </div>
            </div>
            <div *ngIf="passwordSent">
                <div class="alert alert-success">
                    <span>{{'Your password has been changed.'|translate}}</span>
                </div>
            </div>
            <h5 style="margin-top: 0;">{{'Please enter your old and new password'|translate}}</h5>
            <br />
            <div>
                <label>
                    <span class="caption">{{'Old password'|translate}}</span>
                    <input type="password" required class="form-control" [(ngModel)]="currentPassword" name="currentPassword">
                </label>
                <label>
                    <span class="caption">{{'New password'|translate}}</span>
                    <input type="password" required class="form-control" [(ngModel)]="newPassword" name="newPassword">
                </label>
                <label>
                    <span class="caption">{{'Repeat password'|translate}}</span>
                    <input type="password" required class="form-control" [(ngModel)]="repeatPassword" name="repeatPassword" [password-match]="newPassword">
                </label>
                <br />
                <input type="submit" class="btn btn-info" [disabled]="!changePasswordForm.form.valid" value="{{'Change password'|translate}}" />
            </div>
        </fieldset>
    </form>
</div>
