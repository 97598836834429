<div class="airport" [class.selected]="selected" [class.adhoc]="adhoc" [class.nowcast]="airport.UseNowcast">
    <div class="airportCodeWrapper">
        <span class="airportIcon mdi mdi-map-marker" [class.lwe]="airport.HotResponse.Type === 'LWE' || (airport.StationSimulationId || airport.StationGen2Id || airport.StationGen3Id || airport.StationGenVId)" [title]="airport.HotResponse.MetarUsageReason"></span>
        <span *ngIf="airport.HotResponse.Type === 'LWE' || airport.StationSimulationId || airport.StationGen2Id || airport.StationGen3Id || airport.StationGenVId" class="plus" [title]="'LWE'|translate">+</span>

        <span class="airportCode">{{$root.currentUser.UseIataCode ? airport.IATACode : airport.ICAOCode}}</span>
        <span class="hotConditions">
            <span class="mdi barrelIcing mdi-message" *ngIf="airport.BarrelIcingConditionsPresent" [title]="'Barrel icing'|translate"><span class="code">BI</span></span>
            <span class="mdi activeFrost mdi-message" *ngIf="airport.ActiveFrostConditionsPresent" [title]="'Active frost'|translate"><span class="code">AF</span></span>
            <span class="mdi activeFrost mdi-message" *ngIf="airport.ModeratePrecipitationConditionsPresent && ('NowcastGraph'|hasPermission)" [title]="'Moderate precipitation Nowcast'|translate"><span class="code mdi mdi-snowflake"></span></span>
            <span [class]="'hotConditionIcon mdi ' + airport.HotResponse.HotConditionIcon" *ngIf="airport.HotResponse.HotConditionIcon" [title]="airport.HotResponse.HotConditionName" [style.color]="'#' + (airport.HotResponse.HotConditionColor || 'aaaaaa')"></span>
        </span>
        <span class="remove mdi mdi-close-circle-outline" (click)="remove.emit()"></span>
    </div>
    <div class="weather" [title]="airport.HotResponse.Type === 'LWE' ? airport.HotResponse.WeatherType : airport.WeatherTypeNameResult">
        <dispatch-airport-weather-icon [airport]="airport"></dispatch-airport-weather-icon>
        <span class="temperature">{{airport.HotResponse.Temperature|formatTemperature:airport.HotResponse.Type:0}}</span>
    </div>
    <div class="hots">
        <div class="hot type1" *ngIf="airport.Type1Hot?.NonHotMessage && !airport.Type1Hot?.MinHot && !airport.IsClear" [title]="airport.Type1Hot.NonHotMessage"><span [class]="'mdi ' + (airport.Type1Hot.HotConditionIcon || 'mdi-alert-outline')" [style.color]="'#' + (airport.Type1Hot.HotConditionColor || 'aaaaaa')"></span></div>
        <div class="hot type1" *ngIf="airport.Type1Hot?.MinHot">{{airport.Type1Hot.MinHot}}</div>
        <div class="hot type2" *ngIf="airport.Type2Hot?.NonHotMessage && !airport.Type2Hot?.MinHot && !airport.IsClear" [title]="airport.Type2Hot.NonHotMessage"><span [class]="'mdi ' + (airport.Type2Hot.HotConditionIcon || 'mdi-alert-outline')" [style.color]="'#' + (airport.Type2Hot.HotConditionColor || 'aaaaaa')"></span></div>
        <div class="hot type2" *ngIf="airport.Type2Hot?.MinHot">{{airport.Type2Hot.MinHot}}</div>
        <div class="hot type3" *ngIf="airport.Type3Hot?.NonHotMessage && !airport.Type3Hot?.MinHot && !airport.IsClear" [title]="airport.Type3Hot.NonHotMessage"><span [class]="'mdi ' + (airport.Type3Hot.HotConditionIcon || 'mdi-alert-outline')" [style.color]="'#' + (airport.Type3Hot.HotConditionColor || 'aaaaaa')"></span></div>
        <div class="hot type3" *ngIf="airport.Type3Hot?.MinHot">{{airport.Type3Hot.MinHot}}</div>
        <div class="hot type4" *ngIf="airport.Type4Hot?.NonHotMessage && !airport.Type4Hot?.MinHot && !airport.IsClear" [title]="airport.Type4Hot.NonHotMessage"><span [class]="'mdi ' + (airport.Type4Hot.HotConditionIcon || 'mdi-alert-outline')" [style.color]="'#' + (airport.Type4Hot.HotConditionColor || 'aaaaaa')"></span></div>
        <div class="hot type4" *ngIf="airport.Type4Hot?.MinHot">{{airport.Type4Hot.MinHot}}</div>
    </div>
</div>
