import { Injectable } from "@angular/core";
import { ApiService } from "../shared/Api.service";


@Injectable({
    providedIn: 'root'
})
export class FedExShiftService {
    constructor(private api: ApiService) {
    }

    private _selectedIds = new Set<number>();
    private shiftsPromise = this.api.FedexShift.query().$promise;
    
    getAllShifts() {
        return this.shiftsPromise;
    }
    
    get numberOfDesks(): Promise<number> {
        return this.getAllShifts().then(shifts => shifts.length)
    }
    
    getShifts() {
        return this.shiftsPromise.then(shifts => {
            if (this._selectedIds.size === 0) {
                console.log('shift service: no ids selected, returning all shifts')
                return shifts;
            }
            else {
                console.log('shift service: selected ids=', this._selectedIds, 'filtering...')
                const filtered = shifts.filter(shift => this._selectedIds.has(shift.Id))
                console.log('shift service: filtered=', filtered)
                return shifts.filter(shift => this._selectedIds.has(shift.Id))
            }
        })
    }
    
    getSelectedIds() {
        return new Set(this._selectedIds);
    }

    selectShifts(selectedIds: Set<number>): void {
        this._selectedIds = selectedIds;
    }
}
