<nav class="navbar" role="navigation"  [ngStyle]="{backgroundColor: activeBackgroundColor}">
    <div *ngIf="$root.currentUser" class="hamburger-btn" (click)="toggleMenu()" [ngClass]="{open: isMenuOpen}">
        <span class="burger"></span>
    </div>
    <div class="title" [innerHTML]="title$ | async"></div>
    
    <ul *ngIf="$root.currentUser && activeItem && activeItem.children" class="tabs">
        <li *ngFor="let item of activeItem.children" routerLinkActive="active" class="tab"><a [innerHTML]="item.title" [routerLink]="item.route"></a></li>
    </ul>
    <busy-indicator></busy-indicator>
    <a routerLink=""><img class="logo" [src]="'/images/surewx_portals_logo.svg'" width="150" height="35" alt="sure weather portals logo"></a>
</nav>
<div class="menu-backdrop" [hidden]="!isMenuOpen" (click)="onBackdropClick()"></div>
<swx-menu *ngIf="$root.currentUser" class="side-menu" 
          [itemGroups]="itemGroups"
          (itemSelected)="onItemSelected($event)"
          (subMenuClosed)="closeSubMenus()"
          (logout)="logout()"
          [ngClass]="{open: isMenuOpen}" 
          [ngStyle]="{backgroundColor: activeBackgroundColor}"></swx-menu>
