import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { CLIENT_CODE } from '../shared/ClientCode.const';

@Component({
    templateUrl: 'Confirm.component.html'
})
export class ConfirmComponent implements OnInit {
    sent = false;
    error = false;

    constructor(
        private route: ActivatedRoute,
        private api: ApiService
    ) {
    }

    ngOnInit(): void {
        var confirmCode = this.route.snapshot.params['id'];

        this.api.UserConfirmation.post({
                ConfirmationCode: confirmCode,
                ClientCode: CLIENT_CODE
            }, { ignoreErrors: true }).then(result => {
                if (result.Success) {
                    this.sent = true;
                } else {
                    this.error = true;
                }
            })
            .catch(() => {
                this.error = true;
            });
    }
}
