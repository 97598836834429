import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DispatchAirport } from '../shared/Api.service';
import { RootScope } from '../shared/RootScope.service';

@Component({
    selector: 'dispatch-airport',
    templateUrl: 'DispatchAirport.component.html'
})
export class DispatchAirportComponent {
    @Input('airport') airport: DispatchAirport;
    @Input('selected') selected: boolean;
    @Input('adhoc') adhoc: boolean;
    @Output() remove = new EventEmitter();

    constructor(
        public $root: RootScope,
    ) {
    }
}