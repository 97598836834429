import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { AuthenticationService } from 'swx.front-end-lib';
import { RootScope } from '../shared/RootScope.service';
import { CLIENT_CODE } from '../shared/ClientCode.const';

@Component({
    templateUrl: 'UserProfile.component.html'
})
export class UserProfileComponent {
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
    passwordSent = false;
    preferencesSent = false;
    error = false;

    constructor(
        private route: ActivatedRoute,
        private api: ApiService,
        public $root: RootScope,
        private authentication: AuthenticationService,
    ) {
    }

    savePreferences() {
        this.passwordSent = false;
        this.preferencesSent = false;
        this.error = false;
        this.api.User.put('me', {
                UseIataCode: this.$root.currentUser.UseIataCode,
                UseLocalTime: this.$root.currentUser.UseLocalTime,
                UseFahrenheit: this.$root.currentUser.UseFahrenheit
            }).then(result => {
                if (result.Success) {
                    this.preferencesSent = true;
                } else {
                    this.error = true;
                }
            },
            () => {
                this.error = true;
            });
    };

    changePassword() {
        this.passwordSent = false;
        this.preferencesSent = false;
        this.error = false;
        this.api.User.put('me', {
                CurrentPassword: this.currentPassword,
                NewPassword: this.newPassword
            }).then(result => {
                if (result.Success) {
                    this.passwordSent = true;
                    const username = `${CLIENT_CODE.toLowerCase()}${this.$root.currentUser.UserEmail}`
                    this.authentication.setCredentials(username, this.newPassword);
                } else {
                    this.error = true;
                }
            },
            () => {
                this.error = true;
            });
    };
}
