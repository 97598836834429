import { Injectable } from '@angular/core';
import { ApiService } from "./Api.service";
import { Observable, BehaviorSubject, merge } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NowcastService {
    private airportUpdatedSubject = new BehaviorSubject<any>(undefined);
    airportUpdatedAction$ = this.airportUpdatedSubject.asObservable();

    nowcastLwe12Data$: Observable<Array<any>> = this.airportUpdatedAction$
        .pipe(
            switchMap(airport => this.api.Lwe12Graph.query({ airportId: airport.Id }).$promise),
            map(predictions => predictions.map(p => ({
                LeadTime: p.LeadTime,
                Confidence: p.Confidence,
                Prediction: p.Prediction
            })))
        );

    nowcastFromDispatchAirport$: Observable<Array<any>> = this.airportUpdatedAction$
        .pipe(
            map(airport => {
                const predictions = airport.NowcastsLwe12;
                return predictions.map(p => ({
                    LeadTime: p.LeadTime,
                    Confidence: p.Confidence,
                    Prediction: p.Prediction
                }));
            })
        );

    nowcastData$ = merge(this.nowcastLwe12Data$, this.nowcastFromDispatchAirport$);

    constructor(private api: ApiService) {
        
    }

    update(airport) {
        this.airportUpdatedSubject.next(airport);
    }
}
