import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { RootScope } from './shared/RootScope.service';
import { CLIENT_CODE } from './shared/ClientCode.const';
import { AuthenticationService } from 'swx.front-end-lib';
import { BusyIndicatorService } from 'swx.front-end-lib';
import { ApiService } from './shared/Api.service';
import { environment } from './environments/environment';

@Component({
    selector: 'app',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    moment = moment;
    tab;
    defaultLogoName = 'default.png';
    currentDate = new Date();
    @ViewChild('title', { static: true }) title;

    constructor(
        public $root: RootScope,
        private router: Router,
        private api: ApiService,
        private authentication: AuthenticationService,
        public busyIndicatorService: BusyIndicatorService,
    ) {
    }
    
    ngOnInit(): void {
        if (CLIENT_CODE === "undefined" || CLIENT_CODE === "") {
            window.location.href = 'https://www.surewx.com/';
            return;
        }

        this.tab = location.hash ? location.hash.substr(1) : 'hotRequests';
        
        this.authentication.userChange.subscribe(user => {
            if (user != null && window.localStorage.getItem('samlRequestId')) {
                var samlRequestId = window.localStorage.getItem('samlRequestId');
                
                window.localStorage.removeItem('samlRequestId');

                this.api.SamlLoginResponse.get(samlRequestId).$promise.then(data => {
                    window.top.postMessage('tableauSamlPost', '*');

                    var form = document.createElement("form");
                    form.method = "POST";
                    form.action = data.Destination;
                    //form.target = "_top";

                    var inputSAMLResponse = document.createElement("input");
                    inputSAMLResponse.type = "hidden";
                    inputSAMLResponse.name = "SAMLResponse";
                    inputSAMLResponse.value = btoa(data.SAMLResponse);
                    form.appendChild(inputSAMLResponse);

                    var inputRelayState = document.createElement("input");
                    inputRelayState.type = "hidden";
                    inputRelayState.name = "RelayState";
                    if (window.localStorage.getItem('RelayState')) {
                        inputRelayState.value = window.localStorage.getItem('RelayState');
                        window.localStorage.removeItem('RelayState');
                    }
                    form.appendChild(inputRelayState);

                    document.body.appendChild(form);

                    form.submit();
                });
            }
        });
    }

    // Confirm with the user before leaving/refreshing the page
    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (environment.production) {
            event.returnValue = true;
        }
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    register() {
        this.authentication.clearCredentials();
        this.router.navigateByUrl('/register');
    }

    reset() {
        this.router.navigateByUrl('/');
    }
}
