<page-title></page-title>
<div class="contentWrapper">
    <div class="contentMargins">
        <div *ngIf="airports && airports.length > 1">
            <h3>{{getMessagePrompt()|translate}}</h3>
            <section *ngIf="airports" id="airportSelector">
                <button *ngFor="let airport of airports|filter:{Preferred:true}"
                        [class.info]="getAirportCode(airport) !== latestAirport"
                        [class.success]="(getAirportCode(airport) === latestAirport)"
                        (click)="getAirportHots(getAirportCode(airport))">
                    {{getAirportCode(airport)}}
                </button>
            </section>
            <section id="airportSelectorList">
                <div class="autocomplete-list" *ngIf="showAutocompleteList">
                    <label>{{($root.currentUser.UseIataCode ? 'IATA Code' : 'ICAO Code')}}
                        <input #airportCode type="text">
                    </label>
                    <input (click)="getAirportHots(airportCode.value)" type="button" [value]="'Get HOT'|translate" />
                </div>
            </section>
        </div>
        <div>
            <section *ngIf="latestHot">
                <h4>
                    <span>{{(latestHot.Type == 'METAR' || latestHot.Error === '' ? 'SureHOT ' : 'SureHOT+ ')|translate}}</span>
                    <span class="text-muted">{{latestHot.Type}} {{'HOT'|translate}} {{currentAirportCode?.toUpperCase()}}</span>
                    <button (click)="getRecentHots(currentAirportCode)">{{'Refresh HOT'|translate}}</button>
                </h4>
                <textarea *ngIf="latestHot?.HotReport" readonly style="width: 400px; height: 200px; margin-bottom: 50px; white-space: pre; font-family: monospace;">{{latestHot.HotReport.trim()}}</textarea>
                <div *ngIf="latestHot.Error !== ''" class="alert alert-error center-block col-md-6">
                    {{latestHot.Error}}
                </div>
                <div [class.animated]="latestHot.Error === ''" [class.pulse]="latestHot.Error === ''">
                    <hot-response *ngIf="latestHot.Error === ''" [data]="latestHot" [fluids]="selectedAirportFluids"></hot-response>
                </div>
            </section>
            <section *ngIf="('HotGraph'|hasPermission) && query.AirportCode && latestHot" style="clear: both;">
                <button (click)="toggleHotGraph()">{{hotGraphShown ? 'Hide HOT graph' : 'See HOT graph'}}</button>
                <hot-graph *ngIf="hotGraphShown && graphHots && selectedAirportFluids" [data]="graphHots" [fluids]="selectedAirportFluids" [forType1]="true" [count]="graphHotsCount"></hot-graph>
                <hot-graph *ngIf="hotGraphShown && graphHots && selectedAirportFluids" [data]="graphHots" [fluids]="selectedAirportFluids" [forType1]="false" [count]="graphHotsCount"></hot-graph>
            </section>
            <section *ngIf="('HotHistory'|hasPermission) && query.AirportCode && latestHot" style="clear: both;">
                <button (click)="toggleHistory()">
                    {{showHistory ? 'Hide recent HOTs' : 'See recent HOTs'}}
                </button>
                <div *ngIf="showHistory">
                    <h4>
                        <span>{{'HOTs for'|translate}} {{currentAirportCode}}</span>
                    </h4>
                    <label>
                        <span class="caption">{{'Date'|translate}}</span>
                        <input date-time [options]="hotHistoryDatePickerOptions" [(ngModel)]="Datetime" name="Datetime" /> {{$root.currentUser.UseLocalTime ? 'LT' : 'Z'}}
                    </label>
                    <span>{{'*Timestamps are related to the time of weather observation'|translate}}</span>
                    <table>
                        <tr>
                            <th>{{'Type'|translate}}</th>
                            <th *ngFor="let fluid of selectedAirportFluids">{{fluid.ClientFluidName + (fluid.FluidTypeNumber === 1 || !$root.currentClient.ShowFluidConcentration ? '' : (' ' + fluid.Dilution)) + ' (Type ' + fluid.FluidTypeNumber + ')'}}</th>
                            <th>{{'Date'|translate}}</th>
                            <th>{{'*Weather Timestamp'|translate}}</th>
                            <th>{{'Temperature'|translate}}</th>
                            <th>{{'Weather'|translate}}</th>
                        </tr>
                        <tr *ngFor="let historyHot of historyHots.filter(hasNoError)">
                            <td>{{historyHot.Type}}</td>
                            <td class="info" *ngFor="let fluid of selectedAirportFluids">
                                {{historyHot.HotResponseHots?.length > 0 ? findHot(historyHot.HotResponseHots, fluid)?.Message : historyHot.Message}}
                            </td>
                            <td>{{moment.utc(historyHot.Datetime).format($root.dateFormat)}}</td>
                            <td>{{moment.utc(historyHot.Datetime).format($root.timeFormat)}} {{historyHot.SuffixDate}}</td>
                            <td>{{historyHot.Temperature|formatTemperature:historyHot.Type}}</td>
                            <td>{{historyHot.Weather}}</td>
                        </tr>
                    </table>
                </div>
            </section>
            <section *ngIf="('HotRequestHistory'|hasPermission) && query.AirportCode" style="clear: both;">
                <button (click)="toggleHotRequestHistory()">
                    {{(hotRequestHistoryShown ? 'Hide HOT request history' : 'See HOT request history')|translate}}
                </button>
                <div *ngIf="hotRequestHistoryShown">
                    <label>
                        <span class="caption">{{'Date'|translate}}</span>
                        <input date-time [options]="hotRequestHistoryDatePickerOptions" [(ngModel)]="hotRequestQuery.Datetime" name="hotRequestQuery.Datetime" (ngModelChange)="hotRequestQueryChanged($event)" /> {{$root.currentUser.UseLocalTime ? 'LT' : 'Z'}}
                    </label>
                    <label>
                        <span class="caption">{{'Request type'|translate}}</span>
                        <multiselect name="hotRequestQuery.HotRequestType" [(ngModel)]="hotRequestQuery.HotRequestType" (ngModelChange)="hotRequestQueryChanged($event)">
                            <multiselect-option *ngFor="let hotRequestType of hotRequestTypes|keys" [value]="hotRequestType" [name]="hotRequestTypes[hotRequestType]"></multiselect-option>
                        </multiselect>
                    </label>
                    <hot-request-history [data]="hotRequests" [fluids]="selectedAirportFluids"></hot-request-history>
                </div>
            </section>
        </div>
    </div>
</div>
