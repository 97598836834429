import {Component, Input, OnInit} from "@angular/core";
import { FedExShift } from "../shared/Api.service";
import { FedExShiftService } from "./FedExShift.service";


@Component({
    templateUrl: './FedExShiftSelectionDialog.component.html',
    styleUrls: ['./FedExShiftSelectionDialog.component.css']
})
export class FedExShiftSelectionDialogComponent implements OnInit
{
    @Input('container') container: JQuery;
    
    shiftsByShiftName = {};
    selectedIds = new Set<number>();
    selectedShiftName = '';
    searchTerm = '';

    constructor(private shiftService: FedExShiftService) {
        
    }
    
    ngOnInit() {
        this.shiftService.getAllShifts().then(shifts => {
            this.selectedIds = this.shiftService.getSelectedIds();
            this.shiftsByShiftName = this.groupBy(shifts, 'Name')
            console.log('shiftsByShiftName=', this.shiftsByShiftName)
            this.selectedShiftName = Object.keys(this.shiftsByShiftName)[0];
        })
    }

    selectShift(shiftName: string) {
        this.selectedShiftName = shiftName;
    }
    
    filteredDesks(desks: FedExShift[]): FedExShift[] {
        if (desks) {
            return desks.filter(d => d.Desk.includes(this.searchTerm))
        }
        return []
    }
    
    close: () => any;

    toggleShift(evt, shift: FedExShift) {
        if (evt.target.tagName === 'INPUT') {
            if (evt.target.checked) {
                this.selectedIds.add(shift.Id)
            }
            else {
                this.selectedIds.delete(shift.Id);
            }
        }
    }

    isSelected(shift): boolean {
        return this.selectedIds.has(shift.Id);
    } 

    confirmSelection() {
        this.shiftService.selectShifts(this.selectedIds);
        this.container.dialog('close');
    }
    
    cancelSelection() {
        this.container.dialog('close');
    }
    
    filterDesks(searchTerm: string) {
        this.searchTerm = searchTerm.toUpperCase().trim();
    }
    
    private groupBy = (xs, key) =>
        xs.reduce((rv, x) => { (rv[x[key]] = rv[x[key]] || []).push(x); return rv; }, {});
}
