<div class="contentWrapper">
    <div class="contentMargins">
        <h2><strong>SureWx Terms and Conditions of Use</strong></h2>

        <p><strong>Your use of the website at surewx.com and holdovertime.com (the “Website”), including the online
            product
            store (the “Catalogue”),
            applications (the “Apps”) and all accessible content therein (collectively the “Services”) is subject to
            these Terms and Conditions
            (“Terms”) together with our Privacy Policy (this “Agreement”), and any other relevant terms and condition,
            policies, and notices
            which may be posted on the Website or in association with the Apps. Please read the Terms before you start
            using any of the Services.</strong></p>

        <h3><strong>User Acceptance of the Terms and Conditions of Use</strong></h3>
        
        <p>The Services are owned by SureWx Inc. (“<strong>Company</strong>”, “<strong>we</strong>”, “<strong>us</strong>”, “<strong>our</strong>”). Your use of the Services is subject to
            the following Terms. PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY.</p>

        <p>BY ACCESSING AND USING THE SERVICES OR BY CLICKING TO ACCEPT THE TERMS, YOU AGREE, ON YOUR OWN BEHALF AND ON
            BEHALF OF ANY ENTITY FOR WHOM YOU MAY BE ACTING, TO BE BOUND BY THESE TERMS. IF YOU DO NOT WISH TO BE BOUND
            BY THE TERMS, YOU SHOULD NOT USE THE SERVICES.</p>

        <p>As a condition of your use of the Services, you represent and warrant that you will not use the Services for
            any purpose that is unlawful, inappropriate or prohibited by the Terms.</p>
        
        <h3>Modifications to the Terms and Conditions and to the Services</h3>

        <p>We reserve the right in our sole discretion to revise and update these Terms from time to time. Any and all
            such modifications are effective immediately upon posting to the Website and apply to all access to and
            continued use of the Services. You agree to periodically review the Terms in order to be aware of any such
            modifications and your continued use shall be your acceptance of these.</p>

        <p>The information and material available through the Services may be changed, withdrawn, or terminated at any
            time in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the
            Services is restricted to some users or is unavailable at any time or for any period.</p>

        <h3>Security and Privacy</h3>

        <p>The security of your information, including any personal information, is very important to us. We use
            physical, electronic, and administrative measures designed to secure your information from accidental loss
            and from unauthorized access, use, alteration, and disclosure.</p>

        <p>The safety and security of your information also depends on you. Users are responsible for obtaining their
            own access to the Services. Users are required to ensure that all persons who access the Services through a
            user's internet connection are aware of these Terms and comply with them. Some of Services, including the
            content therein or areas of the Website (e.g., the Catalogue), may require specific authentication criteria,
            such as a user ID, password, or other login credential (the “<strong>User Authentication
                Information</strong>”). It is a
            condition of your use of the Services that all the information you provide on to obtain User Authentication
            Information is correct, current, and complete.</p>

        <p>Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our
            best to protect your information, we cannot guarantee the security of your personal information transmitted
            to us over the Internet. Any transmission of personal information is at your own risk. We are not
            responsible for circumvention of any privacy settings or security measures associated with the Services.</p>

        <p>Your provision of registration information and any submissions you make to us through any functionality such
            as applications, e-mail, and other such requests to contact a sales representative or other member of the
            Company constitutes your consent to the collection, use, reproduction, hosting, transmission, and disclosure
            of any related actions we take with respect to such information consistent with our Privacy Policy, found at
            <a href="https://www.surewx.com/Privacy-Policy.pdf" target="_blank">https://www.surewx.com/Privacy-Policy.pdf</a>, as we deem necessary to provide access and use of the
            Services.</p>

        <h3>Access to Services Using User Authentication Information</h3>

        <p>Any User Authentication Information chosen by you, or provided to you as part of our security procedures,
            MUST BE TREATED AS CONFIDENTIAL, and you MUST NOT disclose it to any other person or entity. You must
            exercise caution when accessing the Services from a public or shared computer so that others are not able to
            view or record your User Authentication Information or other personal information. You understand and agree
            that should you be provided User Authentication Information, it is personal to you and you agree not to
            provide any other person with access to the Services or portions of the Services using your User
            Authentication Information. You agree to notify us immediately of any unauthorized access to or use of your
            User Authentication Information or any other breach of security. You also agree to ensure that you logout
            from any applicable portion of the Services (e.g., the Catalogue) at the end of each session. You are
            responsible for any misuse of your User Authentication Information or any unauthorized access to the
            Services.</p>

        <p>We reserve the right at any time and from time to time, to disable or terminate your access to the Services,
            any User Authentication Information, whether chosen by you or provided by us, in our sole discretion for any
            or no reason, including any violation of any provision of these Terms.</p>

        <h3>Confidentiality and Non-disclosure of the Catalogue Contents</h3>

        <p>The Catalogue and the contents therein, including any products and services that the Company offers
            constitutes the Company’s proprietary information (the “Confidential Information”) and is to be accessed
            only with User Authentication Information as administered by the Company.</p>

        <p>You understand and agree that without the prior written consent of the Company, you shall not, directly or
            indirectly, disclose, publish or make accessible to any third-party, and not make any direct or indirect
            commercial use of or exploit or use or share or exploit (including reverse engineering, decompiling, or
            disassembling) any Confidential Information, except to persons who must have such information in order to
            make internal decisions regarding the purchase of our products and services in the Catalogue. You shall
            ensure that such persons will likewise keep secret and not make any direct or indirect commercial use of
            such information.</p>

        <h3>Intellectual Property Rights and Ownership</h3>

        <p>You understand and agree that the Services and the entire contents, features, and functionality, including,
            but not limited to, all information, software, code, text, displays, graphics, photographs, video, audio,
            design, presentation, selection, and any arrangement thereof (the “Content”), are owned by the Company, its
            licensors, or other providers of such material and are protected in all forms by intellectual property laws
            including without limitation, copyright, trademark, patent, trade secret, and any other proprietary rights.
            Content may not be republished, posted, transmitted or distributed in any way without the prior written
            consent of the Company or except as expressly permitted on Services. Modification of the Content or use of
            the Content for any purpose other than your own personal, non-commercial, institutional or home use is a
            violation of copyright and other intellectual property rights. The use of any such material on any other
            website or networked computer is prohibited.</p>

        <p>Everything you see or read on the Services is protected by copyright and as applicable, moral rights, unless
            otherwise noted, and may not be used except as provided in the Terms without the written permission of the
            Company or the owner of the copyright and the holder of the moral rights.</p>

        <p>The trademarks, trade names and logos (the “<strong>Marks</strong>”) that you see on or in association with the Services are
            registered and/or common law marks of the Company or its affiliates and subsidiaries, or third parties who
            have licensed the use of such marks to the Company. Use of any such property, except as expressly
            authorized, shall constitute an infringement or violation of the rights of the property owner and may be a
            violation of federal or other laws and could subject the infringer to legal action.</p>

        <p>Please see the Intellectual Property Legend at the bottom of this document that further identifies the
            ownership of the trademarks and copyrights relating to the Company’s Services.</p>

        <p>Nothing in this site grants any license or right to use any Marks displayed on the Services without the
            written permission of the owner of the mark. Your misuse of the marks displayed on the Services is strictly
            prohibited.</p>

        <p>You have no right, title, or interest in or to the Services or to any content on the Services, and all rights
            not expressly granted are reserved by the Company. Any use of the Services not expressly permitted by these
            Terms is a breach of these and may infringe or violate copyright, trademark, and other intellectual property
            or other proprietary laws.</p>

        <h3>Permitted Use of the Services</h3>

        <p>The Company maintains the Services for informational, educational, and communication purposes in exchange for
            the opportunity to introduce our products and services to you. If you accept the Terms, we give you
            permission to browse the Services for your personal or internal, noncommercial use, and we thank you for
            your interest in our products and services.</p>

        <p>Most computers automatically store content from websites in their temporary folders upon viewing a website.
            The Company only grants you permission to view and display a single temporary copy of the Content
            automatically downloaded from the Website for your personal or internal noncommercial use, provided that you
            do not manually download or store any part of the Content for longer than is reasonably necessary to view
            the Content.</p>

        <h3>Prohibited Use and Conduct</h3>

        <p>Except as expressly provided above, nothing contained in this document shall be construed as conferring any
            license or right to any of the Company’s, or any third parties, intellectual property rights.</p>

        <p>Except as expressly provided above, you do not have the right to copy, produce, reproduce, or publish the
            Content in any material form whatsoever. You do not have the right to copy, produce, reproduce, perform or
            publish any translation of the Content’s text. You do not have the right to communicate the Content to the
            public by telecommunication.</p>

        <p>You do not have the right to modify or prepare derivative works based on the Content, or to make a colorable
            imitation of any of the Content. You do not have the right to use any of the registered or common-law
            trademarks, trade names or logos on the Services. You do not have the right to make, construct, use or sell
            any inventions disclosed on the Services, whether patent protected or not.</p>

        <p>You may not, directly or indirectly, register any domain names which include any of the Company’s trademarks,
            trade names or any domain name confusingly similar to the Company’s trademarks, trade names or domain
            names.</p>

        <p>You are prohibited from attempting to circumvent and from violating the security of the Services, including,
            without limitation: (a) accessing content and data that is not intended for you; (b) attempting to breach or
            breaching the security and/or authentication measures which are not authorized; (c) restricting, disrupting
            or disabling service to users, hosts, servers, or networks; (d) illicitly reproducing TCP/IP packet header;
            (e) disrupting network services and otherwise disrupting the Company’s ability to monitor the Services; (f)
            using any robot, spider, or other automatic device, process, or means to access the Services for any
            purpose, including monitoring or copying any of the material found on the Services; (g) introducing any
            viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;
            (h) attacking the Services via a denial-of-service attack, distributed denial-of-service attack, flooding,
            mailbombing, or crashing; (i) make any changes to the Services; post or send to the Services any unlawful,
            fraudulent, harassing, threatening, defamatory, indecent, sexually explicit, libelous, obscene information
            of any kind or otherwise violate any applicable law; (j) re-deliver any of the Services or the content using
            "framing", hyperlinks, or other technology without the Company’s permission; (k) take any action which may
            interfere with any other user’s use and enjoyment of the Services; and (l) otherwise attempting to interfere
            with the proper working of the Services.</p>

        <p>You do not have the right to authorize anyone else to do any of these prohibited acts.</p>

        <p>Any violation of the Terms, including any violation of this section is strictly prohibited and a breach of
            the Terms.</p>

        <h3>User Submissions</h3>

        <p>Subject to our Privacy Policy, any information that you otherwise transmit to the Company by email or
            otherwise, including any questions or answers, comments, suggestions, or the like will become the property
            of the Company (collectively “User Submissions”) and, with respect to your rights, will be treated as
            non-confidential and non-proprietary. Anything you transmit may be used without compensation by the Company,
            or its affiliates, or licensed to others by the Company, or its affiliates, for any and all commercial and
            noncommercial purposes, including but not limited to, mailing list rental, product or service solicitations,
            reproduction, disclosure, transmission, publication, broadcast, and posting.</p>

        <p>You may not submit any material that is unlawful, harmful, harassing, threatening, abusive, hateful,
            libelous, defamatory, obscene, pornographic, profane, vulgar, indecent, sexually explicit or otherwise
            objectionable that would constitute a criminal offense, give rise to civil liability, or otherwise violate
            any applicable law. Please do not submit any material that is protected by trademark, copyright, patent, or
            other proprietary right without obtaining permission of the owner of that proprietary right.</p>

        <h3>No Reliance</h3>

        <p>The Content associated with the Services is provided for general information purposes only. It is not
            intended to amount to advice on which you should rely. You must obtain more specific or professional advice
            before taking, or refraining from, any action or inaction on the basis of the content on our Services.</p>

        <p>Although we make reasonable efforts to update the information on our Website, we make no representations,
            warranties, or guarantees, whether express or implied, that the content on our Website is accurate,
            complete, or up to date. Your use of the Website is at your own risk and neither the Company [nor its
            parent, subsidiaries, affiliates, and their respective directors, officers, employees, agents, service
            providers, contractors, licensors, licensees, suppliers, or successors have any responsibility or liability
            whatsoever for your use of the Website.</p>

        <h3>Disclaimer of Warranties</h3>

        <p>Without limiting any other term or condition herein, YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES,
            ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE SERVICES IS AT YOUR OWN RISK. THE
            SERVICES, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE SERVICES ARE PROVIDED ON AN
            "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR
            IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
            LIMITED UNDER APPLICABLE LAW.</p>

        <p>NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS,
            EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY
            WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
            SUITABILITY, ACCURACY, CURRENCY, OR AVAILABILITY OF THE SERVICES OR ITS CONTENTS. WITHOUT LIMITING THE
            FOREGOING, NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS,
            OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS
            REPRESENT OR WARRANT THAT THE SERVICES, ITS CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE
            SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
            SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>

        <p>WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR
            THE SERVICES WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR
            YOUR USE OF THE SERVICES AND YOUR COMPUTER, INTERNET, AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY
            LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED
            DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS,
            LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER
            PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICES OR ANY SERVICES OR ITEMS FOUND
            OR ATTAINED THROUGH THE SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
            LINKED TO IT.</p>

        <p>YOUR SOLE REMEDY AGAINST THE COMPANY FOR DISSATISFACTION WITH THE SERVICES, ITS CONTENT OR THE TERMS IS TO
            STOP USING THE SERVICES OR ANY SUCH CONTENT. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE
            PARTIES.</p>

        <h3>Limitation on Liability</h3>

        <p>EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL THE COMPANY NOR ITS PARENT,
            SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
            CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE,
            NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY
            DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO,
            PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
            OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
            NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR
            HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE
            WEBSITE, ANY LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT, MATERIALS,
            POSTING, OR INFORMATION THEREON EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW.</p>

        <h3>Indemnification</h3>

        <p>To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless the
            Company, its parent, subsidiaries, affiliates, and their respective directors, officers, employees, agents,
            service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims,
            liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys'
            fees) arising out of or relating to your breach of these Terms or your use of the Services, including, but
            not limited to, your User Submissions, any use of the Website's content, the Catalogue, services, and
            products other than as expressly authorized in these Terms.</p>

        <h3>Governing Law and Choice of Forum</h3>

        <p>The Services and these Terms will be governed by and construed in accordance with the laws of the Province of
            Ontario and the federal laws of Canada applicable therein, without giving effect to any choice or conflict
            of law provision, principle, or rule (whether of the laws of the Province of Ontario or any other
            jurisdiction) and notwithstanding your domicile, residence, or physical location.</p>

        <p>Any action or proceeding arising out of or relating to this Website and under these Terms will be instituted
            in the courts of the Province Ontario and/or the Federal Court of Canada, and each party irrevocably submits
            to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and all
            objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.</p>


        <h3>Waiver</h3>

        <p>No failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from these
            Terms operates, or may be construed, as a waiver thereof. No single or partial exercise of any right,
            remedy, power, or privilege hereunder precludes any other or further exercise thereof or the exercise of any
            other right, remedy, power, or privilege.</p>

        <h3>Severability</h3>
        
        <p>If any term or provision of these Terms is invalid, illegal, or unenforceable in any jurisdiction, such
            invalidity, illegality, or unenforceability shall not affect any other term or provision of these Terms or
            invalidate or render unenforceable such term or provision in any other jurisdiction.</p>

        <h3>Entire Agreement</h3>

        <p>The Terms and our Privacy Policy constitute the sole and entire agreement between you and the Company
            regarding the Services and supersedes all prior and contemporaneous understandings, agreements,
            representations and warranties, both written and oral, regarding such subject matter.</p>

        <h3>Survival</h3>
        
        <p>All provisions regarding indemnification, warranty, liability, and limits thereon, and confidentiality and/or
            protections of intellectual property rights, and proprietary rights shall survive the termination of this
            Agreement.</p>

        <h3>Reporting and Contact</h3>

        <p>Notifications regarding the Services or these Terms can be directed to:</p>

        
        <address style="padding: 10px;">
            <p>SureWx Inc.<br>
                Suite 400, 6500 Trans-Canada Highway<br>
                Pointe Claire, QC, H9R 0A5.<br></p>
        </address>
        
        <p>Should you become aware of misuse of the Services, including libelous or defamatory conduct, you must report
            it to the Company at legal&#64;surewx.com. All other feedback, comments, requests for technical support, and
            other communications relating to the Services should be directed to product&#64;surewx.com.</p>

        <h3>Intellectual Property Legend</h3>

        <p>This Services are © SureWx Inc. 2022. All rights reserved.</p>

        <p>SUREWX, <img src="images/swx_blue.png" width="100px" alt="sure weather logo">, SUREHOT, SUREHOT+, SUREAPPS, SUREDETECT, SURECDM, SURECAST are trademarks owned by SureWx Inc. All
            rights reserved.</p>

        <p>Last modified March 27, 2023.</p>

    </div>
</div>
