<page-title></page-title>
<div class="contentWrapper">
    <article class="alignCenter">
        <div *ngIf="error">
            <div class="alert alert-error">
                <span>{{'Your search did not return any results.'|translate}} <a routerLink="/login">{{'Click here'|translate}}</a> {{'to try again.'|translate}}</span>
            </div>
        </div>
        <div *ngIf="!error">
            <div *ngIf="sent">
                <div class="alert alert-success">
                    <span>{{'Instructions for resetting your password have been sent to your email address.'|translate}}</span>
                </div>
            </div>
            <div *ngIf="!sent">
                <section class="login center-block" *ngIf="!error">
                    <form class="form" #form="ngForm" (submit)="submit()">
                        <fieldset>
                            <h3 class="title">{{'Forgot password?'|translate}}</h3>
                            <h4>{{'Please enter your email address to continue'|translate}}</h4>
                            <br />
                            <div>
                                <div class="form-group">
                                    <label>{{'Email'}}</label>
                                    <input type="email" name="email" [(ngModel)]="email" required class="form-control" placeholder="user@example.com" />
                                </div>
                                <br />
                                <input type="submit" class="btn btn-info" [disabled]="!form.form.valid" value="{{'Send email'|translate}}" />&nbsp;&nbsp;&nbsp;&nbsp;<a routerLink="/login">{{'Cancel'}}</a>
                                <br /><br />
                            </div>
                        </fieldset>
                    </form>
                </section>
            </div>
        </div>
    </article>
</div>
