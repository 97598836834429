<div style="display: flex; flex-direction: column; height: 100%;">
    <div class="toolbar">
        <search-box (search)="filterDesks($event)"></search-box>
    </div>
    <div class="toolbar">
        <a *ngFor="let shift of shiftsByShiftName|keyvalue"
           class="tab"
           [ngClass]="{active: shift.key === selectedShiftName}"
           (click)="selectShift(shift.key)">
            {{shift.key}}
        </a>
    </div>
    <div style="flex: 1; overflow-y: scroll;">
        <ul>
            <li *ngFor="let desk of filteredDesks(shiftsByShiftName[selectedShiftName])">
                <label (click)="toggleShift($event, desk)">
                    <input type="checkbox" [checked]="isSelected(desk)"/>
                    {{desk.Desk}}
                </label>
            </li>
        </ul>
    </div>
    <hr />
    <div class="buttons">
        <button class="btn" (click)="cancelSelection()"><span class="mdi mdi-close">{{'Cancel'|translate}}</span></button>
        <button class="btn" (click)="confirmSelection()"><span class="mdi mdi-check"></span> {{'Confirm'|translate}}</button>
    </div>
</div>

