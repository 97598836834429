<page-title></page-title>
<div class="contentWrapper" style="padding-top: 10px;">
    <article class="alignCenter">
        <section class="login center-block">
            <div *ngIf="sent" class="alert alert-success">
                {{'Your registration is now complete. Please log in by clicking on the following link:'}} <strong><a routerLink="/login">{{'Login'}}</a></strong>
            </div>
            <div *ngIf="error" class="alert alert-error">
                <span>{{'The confirmation code is invalid.'|translate}}</span> <strong><a routerLink="/login">{{'Login'}}</a></strong>
            </div>
        </section>
    </article>
</div>

