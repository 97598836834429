<div class="legend">
    <button class="legend-toggle" (click)="toggle()">Legend <span [ngClass]="getToggleIcon()"></span></button>
    <ul class="legend-content" [ngStyle]="{ display: this.open ? 'block' : 'none' }">
        <li *ngFor="let hotCondition of hotConditions | orderBy: 'Rank'">
            <i [ngStyle]="{ color: colorForIcon(hotCondition) }" [ngClass]="classForIcon(hotCondition)"></i> {{hotCondition.Name}}<span class="star" *ngIf="hotCondition.ShowAlertWhenReached">*</span>
        </li>
        <li class="remark"><span class="star">*</span> show alert when reached</li>
    </ul>
</div>

