import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import moment from 'moment';
import jQuery from 'jquery';
import { ApiService } from '../shared/Api.service';
import { RootScope } from '../shared/RootScope.service';
import 'jquery-ui/ui/widgets/autocomplete.js';
import { GoogleChartsService } from 'swx.front-end-lib';

@Component({
    templateUrl: 'DeIcingPortal.component.html'
})
export class DeIcingPortalComponent implements OnInit, AfterViewInit, OnDestroy {
	moment = moment;
	Math = Math;
	dateTime = moment.utc();
	dateString: string;
	dateTimeTime = moment.utc().hour() * 60 + moment.utc().minute();
	datePickerOptions: any;
	refreshInterval;
	playInterval;
	airportSearchOptions: any[];
	airports;
	adHocAirports = [];
	airportSearch: any;
	selectedAirport;
	flights: any[];
	flightCategories = [
		{
			id: 'out',
			title: 'Push Back to Taxi in',
			backgroundColor: '#003f5c',
			color: '#ffffff',
			startField: 'OffBlockTime',
			endField: 'TaxiInTime',
		},
		{
			id: 'taxiIn',
			title: 'Taxi in to Configured',
			backgroundColor: '#58508d',
			color: '#ffffff',
			startField: 'TaxiInTime',
			endField: 'IcemanAcceptInTime',
		},
		{
			id: 'parkIn',
			title: 'Deicing Time',
			backgroundColor: '#bc5090',
			color: '#ffffff',
			startField: 'IcemanAcceptInTime',
			endField: 'IcehouseAcceptOutTime',
		},
		{
			id: 'ctm',
			title: 'CTM (Call Time) to Taxi Out',
			backgroundColor: '#ff6361',
			color: '#ffffff',
			startField: 'IcehouseAcceptOutTime',
			endField: 'TaxiOutTime',
		},
		{
			id: 'taxiOut',
			title: 'Taxi Out to Wheels off',
			backgroundColor: '#ffa600',
			color: '#ffffff',
			startField: 'TaxiOutTime',
			endField: 'WheelsUpTime',
		},
	];
	categorizedFlights: any;
	@ViewChild('chartEl', { static: false }) chartEl: ElementRef<HTMLElement>;
    chartSampleCount = 24;
    columnChart: google.visualization.ColumnChart;
	resizer: any;
	chartOptions: google.visualization.ColumnChartOptions = {};

    constructor(
		private elementRef: ElementRef,
		private googleChartsLoaderService: GoogleChartsService,
		private api: ApiService,
		public $root: RootScope,
	) {
		this.datePickerOptions = {
			minDate: '2022-03-10',
			maxDate: moment.utc().add(1, 'day').format('YYYY-MM-DD'),
		};
    }

	ngOnInit(): void {
		this.$root.currentClient.$promise.then(() => {
			this.refreshInterval = setInterval(() => {
				this.dateTime = moment.utc();
				this.refresh();
			}, this.$root.currentClient.DeIcingPortalRefreshSeconds * 1000);
		});

		var deicingPortalSearch = jQuery(this.elementRef.nativeElement).find('.deicingPortalSearch');

		// HACK to position search box
		setTimeout(() => {
            deicingPortalSearch.css('left', jQuery('#navigationBar .navbar-nav').width() + 25);
		}, 100);

		var dragContainer = jQuery(this.elementRef.nativeElement).find('.content');
		var topPane = dragContainer.find('.topPane');
		var bottomPane = dragContainer.find('.bottomPane');
		var horizontalBorder = dragContainer.find('.horizontalBorder');

		var deicingPortalHeight = localStorage.getItem('deicingPortalHeight')
			? Math.min(dragContainer.innerHeight() - 8, Math.max(0, parseInt(localStorage.getItem('deicingPortalHeight'))))
			: null;
		
		var applyHeight = (top) => {
			topPane.css({ height: top });
			bottomPane.css({ top: top + 8 });
			localStorage.setItem('deicingPortalHeight', top);
            deicingPortalHeight = top;

			if (this.columnChart != null) {
                this.chartOptions.height = bottomPane.innerHeight();
				this.drawChart();
            }
		}

		if (deicingPortalHeight != null) {
			applyHeight(deicingPortalHeight);
			horizontalBorder.css({ top: deicingPortalHeight });
		}

		horizontalBorder.draggable({
			axis: 'y',
			drag: (e, ui) => {
				ui.position.top = Math.min(dragContainer.innerHeight() - 8, Math.max(0, ui.position.top));
				applyHeight(ui.position.top);
				jQuery(window).trigger('resize');
			}
		});
        
		var resizeTimer;
		this.resizer = () => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				if (deicingPortalHeight && deicingPortalHeight > dragContainer.innerHeight()) {
                    deicingPortalHeight = Math.max(0, dragContainer.innerHeight() - 20);
					horizontalBorder.css({ top: deicingPortalHeight });
					applyHeight(deicingPortalHeight);
                }
                else if (this.columnChart != null) {
					this.chartOptions.height = bottomPane.innerHeight();
					this.drawChart();
                }
			}, 250);
		};
		window.addEventListener('resize', this.resizer);

		var urlParams = new URLSearchParams(window.location.search);
		var ts = urlParams.get('ts');
		if (ts) {
			clearInterval(this.refreshInterval);
			this.refreshInterval = null;
			this.dateString = ts;
			this.dateTime = moment.utc(this.dateString);
		}

		this.refresh().then(() => {
			var wasOpen = false;
            
            var input = deicingPortalSearch.find('[name=deicingPortalSearch]');

            input.autocomplete({
                delay: 0,
                minLength: 0,
                source: (request, responseCallback) => responseCallback(this.airportSearchOptions
	                    .filter(o => {
	                        return !request.term || o.label.toLowerCase().indexOf(request.term.toLowerCase()) !== -1;
	                    })),
                classes: {
                    'ui-autocomplete': 'autocomplete-component'
                },
                select: (e, ui) => {
                    this.selectAirport(this.airports.find(a => a.Id === ui.item.id));

                    e.preventDefault();
                    this.airportSearch = null;
                    setTimeout(() => {
                        input.val('');
                    });
                },
                close: () => {
                    setTimeout(() => {
                        this.airportSearch = null;
                        input.val('');
                    }, 1500);
                }
            });

            deicingPortalSearch
                .on('mousedown', () => wasOpen = input.autocomplete('widget').is(':visible'))
                .on('click', () => {
                    input.trigger('focus');
                    if (wasOpen) return;
                    input.autocomplete('search', '');
                });
		});
	}

    ngAfterViewInit(): void {
        this.googleChartsLoaderService.loadGoogleCharts().then(() => {
            this.columnChart = new google.visualization.ColumnChart(this.chartEl.nativeElement);
        });
    }

    ngOnDestroy(): void {
		if (this.refreshInterval != null) {
			clearInterval(this.refreshInterval);
		}
		
		window.removeEventListener('resize', this.resizer);

		this.pause();
	}

	updateDate() {
		this.pause();
		this.dateTime = moment.utc(this.dateString);
		this.refresh();
	}

	updateTime() {
		this.pause();
		this.dateTime = moment.utc(this.dateString).hour(0).minute(this.dateTimeTime);
		this.categorizeFlights();
	}

	play() {
		this.playInterval = setInterval(() => {
			this.dateTimeTime++;
			if (this.dateTimeTime > 1438) {
				this.pause();
			} else {
				this.dateTime = moment.utc(this.dateString).hour(0).minute(this.dateTimeTime);
				this.categorizeFlights();
			}
		}, 1000);
	}

	pause() {
		if (this.refreshInterval != null) {
			clearInterval(this.refreshInterval);
			this.refreshInterval = null;
		} else if (this.playInterval != null) {
			clearInterval(this.playInterval);
			this.playInterval = null;
		}
	}

	refreshClick() {
		if (this.playInterval != null) {
			clearInterval(this.playInterval);
			this.playInterval = null;
		}

		if (this.refreshInterval == null) {
			this.refreshInterval = setInterval(() => {
				this.dateTime = moment.utc();
				this.refresh();
			},
			this.$root.currentClient.DeIcingPortalRefreshSeconds * 1000);
		}

		this.dateTime = moment.utc();
		this.refresh();
	}

	refresh() {
		this.dateString = this.dateTime.toISOString();
		this.dateTimeTime = moment.utc(this.dateString).hour() * 60 + moment.utc(this.dateString).minute();

		return this.api.ClientAirport.query().$promise.then(airports => {
			this.airports = airports;

			this.airportSearchOptions = this.airports.map(item =>
				({
					id: item.Id,
					label: this.$root.currentUser.UseIataCode
						? (item.IATACode + '/' + item.ICAOCode)
						: (item.ICAOCode + '/' + item.IATACode),
				}));

			this.adHocAirports = this.adHocAirports.map(aa => this.airports.find(a => a.Id === aa.Id)).filter(a => a != null);

			if (this.airports.length === 1) {
				this.selectAirport(this.airports[0]);
			} else if (this.selectedAirport != null) {
				this.selectedAirport = this.airports.find(a => a.ICAOCode === this.selectedAirport.ICAOCode);
			}

			if (this.selectedAirport != null) {
				this.refreshSelectedAirport();
			}
		});
	}

	selectAirport(airport) {
		this.selectedAirport = airport;

		if (!airport.Preferred && !this.adHocAirports.some(a => a.Id === airport.Id)) {
			this.adHocAirports.push(airport);
		}

		this.refreshSelectedAirport();
	}

	refreshSelectedAirport() {
		if (this.selectedAirport != null) {
			this.api.DeIcingPortalFlight.query({
				AirportId: this.selectedAirport.Id,
				DateTime: this.dateTime.toDate().toISOString(),
			}).$promise.then(flights => {
				this.flights = flights;
				this.categorizeFlights();
			});
		}
	}

	categorizeFlightsAtTime(now) {
		var categorizedFlights = {
			taxiOut: { flights: [], count: 0, total: 0, average: null },
			ctm: { flights: [], count: 0, total: 0, average: null },
			parkIn: { flights: [], count: 0, total: 0, average: null },
			taxiIn: { flights: [], count: 0, total: 0, average: null },
			out: { flights: [], count: 0, total: 0, average: null },
		};
		
		var nowMoment = moment(now);
		
		for (let originalFlight of this.flights) {
			var flight: any = Object.assign({}, originalFlight);
			
			if ((flight.WheelsUpTime != null && flight.WheelsUpTime < now)) {
				continue;
			}

			if (flight.TaxiOutTime < now) {
				if (flight.OffBlockTime < now
						&& (flight.WheelsUpTime == null || flight.WheelsUpTime > now)
						&& nowMoment.diff(flight.WheelsUpTime, 'hour') < 2) {
					flight.laneTime = nowMoment.diff(flight.TaxiOutTime, 'minutes', true);
					categorizedFlights['taxiOut'].flights.push(flight);
                    if (flight.laneTime <= 40) {
                        categorizedFlights['taxiOut'].total += flight.laneTime;
                        categorizedFlights['taxiOut'].count++;
                    }
				}
			} else if (flight.IcehouseAcceptOutTime < now
					&& nowMoment.diff(flight.IcehouseAcceptOutTime, 'hour') < 2) {
				flight.laneTime = nowMoment.diff(flight.IcehouseAcceptOutTime, 'minutes', true);
				categorizedFlights['ctm'].flights.push(flight);
                if (flight.laneTime <= 40) {
                    categorizedFlights['ctm'].total += flight.laneTime;
                    categorizedFlights['ctm'].count++;
                }
			} else if (flight.IcemanAcceptInTime < now
					&& nowMoment.diff(flight.IcemanAcceptInTime, 'hour') < 2) {
				flight.laneTime = nowMoment.diff(flight.IcemanAcceptInTime, 'minutes', true);
				categorizedFlights['parkIn'].flights.push(flight);
                if (flight.laneTime <= 40) {
                    categorizedFlights['parkIn'].total += flight.laneTime;
                    categorizedFlights['parkIn'].count++;
                }
			} else if (flight.TaxiInTime < now
					&& nowMoment.diff(flight.TaxiInTime, 'hour') < 2) {
				flight.laneTime = nowMoment.diff(flight.TaxiInTime, 'minutes', true);
				categorizedFlights['taxiIn'].flights.push(flight);
                if (flight.laneTime <= 40) {
                    categorizedFlights['taxiIn'].total += flight.laneTime;
                    categorizedFlights['taxiIn'].count++;
                }
			} else if (flight.OffBlockTime < now
					&& nowMoment.diff(flight.OffBlockTime, 'hour') < 2) {
				flight.laneTime = nowMoment.diff(flight.OffBlockTime, 'minutes', true);
				categorizedFlights['out'].flights.push(flight);
                if (flight.laneTime <= 40) {
                    categorizedFlights['out'].total += flight.laneTime;
                    categorizedFlights['out'].count++;
                }
			}
		}

		for (let flightCategory of this.flightCategories) {
			categorizedFlights[flightCategory.id].average = categorizedFlights[flightCategory.id].count > 0
				? (categorizedFlights[flightCategory.id].total / categorizedFlights[flightCategory.id].count)
				: null;
		}

		return categorizedFlights;
	}

	categorizeFlights() {
		this.categorizedFlights = this.categorizeFlightsAtTime(this.dateTime.toISOString());
        this.drawChart();
    }

	private drawChart(): void {
		this.googleChartsLoaderService.loadGoogleCharts().then(() => {
			let dataTable = new google.visualization.DataTable();
			dataTable.addColumn('datetime', this.$root.currentUser.UseLocalTime ? 'Local time' : 'UTC');

			let series: any = {};
			let i = 0;
			for (let flightCategory of this.flightCategories) {
				dataTable.addColumn('number', 'Avg ' + flightCategory.title);
				series[i] = { color: flightCategory.backgroundColor };
				i++;
			}

			for (let i = -1; i < this.chartSampleCount - 1; i++) {
				const dateTime = this.dateTime.clone().add(-i, 'hour').minute(0).second(0);
				const dateTimeString = dateTime.toISOString();
				const parts = dateTimeString.split(/[^0-9]/);
				const chartDateTime = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]), parseInt(parts[3]), parseInt(parts[4]), parseInt(parts[5]));

				let row: any[] = [chartDateTime];

				const startTime = dateTime.clone().subtract(1, 'hour').minute(0).second(0).toISOString();

				for (let flightCategory of this.flightCategories) {
					var total = 0;
					var count = 0;
					for (let flight of this.flights) {
						if (flight[flightCategory.startField] != null
							&& flight[flightCategory.startField] >= startTime
							&& flight[flightCategory.endField] != null
							&& flight[flightCategory.endField] < dateTimeString) {
							var laneTime = (moment(flight[flightCategory.endField]).diff(flight[flightCategory.startField], 'minutes', true));

							if (laneTime > 0 && laneTime <= 60) {
								total += laneTime;
								count++;
							}
						}
					}

					row.push(count > 0 ? (total / count) : 0);
				}

				dataTable.addRow(row);
			}

			// show result to one decimal
			const formatter = new google.visualization.NumberFormat({ fractionDigits: 1 });
			[1, 2, 3, 4, 5].forEach(columnIndex => formatter.format(dataTable, columnIndex));

            this.chartOptions = {
                ...this.chartOptions,
				height: jQuery(this.elementRef.nativeElement).find('.bottomPane').innerHeight(),
                backgroundColor: {
                    stroke: '#31b0d5',
                    strokeWidth: 1
                },
                isStacked: true,
                hAxis: {
                    title: 'Zulu Time (ending in)',
                    format: 'HH:mm',
                    gridlines: {
                        count: this.chartSampleCount
                    },
                    viewWindowMode: 'maximized',
                },
                series: series,
                legend: {
                    position: 'bottom',
                    maxLines: 3
                },
                //enableInteractivity: false,
                //interpolateNulls: true,
                vAxis: {
                    title: 'Average Throughput Time (minutes)',
                    viewWindow: {
                        min: 0,
                        //max: maxHot > 48 ? 240 : (maxHot > 24 ? 48 : 24)
                    }
                },
                chartArea: {
                    width: '90%',
                    bottom: 100,
                    left: 100,
                    right: 20,
                    top: 20,
                },
            };
            
			this.columnChart.draw(dataTable, this.chartOptions);
		});
    }

	// TODO: demo predicted time value - 5%
	getNarrowPredictedTime(value) {
        let result = value - value * .2;
        return Math.round(result * 10) / 10;
    }

	// TODO: demo predicted time value + 5%
	getWidePredictedTime(value) {
        let result = value + value * .2;
        return Math.round(result * 10) / 10;
    }
}
