<div class="nowcast-graph">
    <div #chartEl></div>
    <div class="nowcast-legend">
        <div class="legend-item">
            <svg class="legend-icon">
                <rect x="0" y="0" width="30" height="10" fill="#3366cc" />
            </svg>
            <span class="legend-text">Moderate or Heavy Snow</span>
        </div>
    </div>
    <div class="overlay" *ngIf="!data.length"><span class="mdi mdi-alert"></span> Forecast data is not available at this time</div>
</div>
