import {
    MobileClient,
    MobileClientFluid,
    FluidMetarSpec,
    MetarMessageMapping,
} from '../shared/Api.service';

export class OfflineHot {
    fluidName: string;
    fluidType: number;
    fluidId: number;
    fluidSeasonId: number;
    fluidVersionId: number;
    fluidDilution: string;
    fluidNotes: string;
    fluidCautions: string;
    fluidOrder: number;
    sortableDilution?: number;
    sortableName: string;
    holdoverTime: string;
    mobileHoldoverTime: string;
    MinHot?: number;
    MaxHot?: number;
}

export class HotBuilderService {
    private static HotRegex = new RegExp(`^([0-9]+)-?([0-9]+)? MIN\n?((.|\n)+)?`);
    private static MetarWeathertypeCLR = 47;

    static parseHot(hot: string) {
        var hotMatch = hot === null || hot === '' ? null : this.HotRegex.exec(hot);
        var minHot = hotMatch !== null ? parseInt(hotMatch[1]) : null;
        var maxHot = hotMatch !== null && typeof hotMatch[2] !== 'undefined' ? parseInt(hotMatch[2]) : null;
        return {
            MinHot: minHot,
            MaxHot: maxHot,
            Message: hotMatch === null ? hot : hotMatch[3]
        };
    }

    private static filterMessage(message: string): string {
        return message.replace(/\\N/g, "\n");
    }

    private static getFluidMetarSpec(fluidMetarSpecs: Array<FluidMetarSpec>, fluidId: number, metarWeatherTypeId: number, temperatureC: number, ref: any): FluidMetarSpec {
        var temps = fluidMetarSpecs.filter(fs => fs.FluidId === fluidId)
            .map(fms => fms.MinTemp);

        var lout = (temps.length === 0 ? [Number.MIN_VALUE] : temps)
            .reduce((t1, t2) => Math.min(t1, t2));

        if (lout >= temperatureC) {
            ref.isOatBelowLout = true;
            return null;
        }

        var fluidMetarSpec = null;
        fluidMetarSpecs.forEach(candidate => {
            if (candidate.MaxTemp >= temperatureC
                    && candidate.MetarWeatherTypeId === metarWeatherTypeId
                    && candidate.FluidId === fluidId
                    && candidate.MinTemp < temperatureC
                    && (fluidMetarSpec === null || fluidMetarSpec.MinTemp < candidate.MinTemp)) {
                fluidMetarSpec = candidate;
            }
        });
        return fluidMetarSpec;
    }

    private static findMetarMessageMapping(metarWeathertype: number, metarMessageMappings: Array<MetarMessageMapping>, fluidType: string, temperatureC: number): MetarMessageMapping {
        var metarMessageMapping = null;
        metarMessageMappings.forEach(candidate => {
            if (candidate.MetarWeatherType.some(mwt => mwt === metarWeathertype)
                && candidate.FluidType.some(ft => ft === fluidType)
                && candidate.MaxTemp >= temperatureC
                && candidate.EquipmentTypeId == null
                && (metarMessageMapping === null || metarMessageMapping.MaxTemp > candidate.MaxTemp)) {
                metarMessageMapping = candidate;
            }
        });
        return metarMessageMapping;
    }

    static getManualHot(
        client: MobileClient,
        metarWeathertype: number,
        temperatureC: number,
        fluid: MobileClientFluid,
        fluidMetarSpecs: Array<FluidMetarSpec>,
        metarMessageMappings: Array<MetarMessageMapping>,
    ): OfflineHot {
        var fluidMessage;
        var mobileFluidMessage;
        var fluidNotes = "";
        var defaultHot = 'USE HOLDOVER TABLES';

        var metarMessageMapping = this.findMetarMessageMapping(metarWeathertype, metarMessageMappings, fluid.Type, temperatureC);
        
        if (metarMessageMapping != null && !metarMessageMapping.ShowHotAndMessage) {
            fluidMessage = metarMessageMapping.Message;
            mobileFluidMessage = metarMessageMapping.MobileMessage;
        } else {
            var ref = { isOatBelowLout: false };
            var fluidMetarSpec = this.getFluidMetarSpec(fluidMetarSpecs, fluid.FluidId, metarWeathertype, temperatureC, ref);

            if (fluidMetarSpec != null) {
                var minValue = fluidMetarSpec.MinHot.toFixed(0);
                var maxValue = fluidMetarSpec.MaxHot.toFixed(0);
                var useRange = !client.ShowOnlyMinHot && minValue !== maxValue;

                fluidMessage = (useRange ? (minValue + "-" + maxValue) : minValue) + " MIN";
                mobileFluidMessage = "";

                if (metarMessageMapping != null && metarMessageMapping.ShowHotAndMessage) {
                    fluidMessage = fluidMessage + "\n" + metarMessageMapping.Message;
                    mobileFluidMessage = metarMessageMapping.MobileMessage;
                }

                fluidNotes = fluidMetarSpec.Notes;
            } else if (ref.isOatBelowLout) {
                fluidMessage = client.OatBelowFluidLoutError || defaultHot;
                mobileFluidMessage = client.MobileOatBelowFluidLoutError;
            } else if (metarWeathertype === HotBuilderService.MetarWeathertypeCLR) {
                fluidMessage = client.MetarEmptyWeatherCodeError || defaultHot;
                mobileFluidMessage = client.MobileMetarEmptyWeatherCodeError;
            } else {
                fluidMessage = client.FluidMetarSpecNotFoundError || defaultHot;
                mobileFluidMessage = client.MobileFluidMetarSpecNotFoundError;
            }
        }

        var fluidType = parseInt(fluid.Type.substring(4));

        var holdoverTime = this.filterMessage(fluidMessage || '');
        var hotDetails = HotBuilderService.parseHot(holdoverTime);

        var fluidName = fluidType !== 1
            ? (fluid.Name + ' - ' + fluid.Dilution)
            : fluid.Name;

        var sortableDilution = parseInt(fluid.Dilution.indexOf('/') !== -1 ? fluid.Dilution.substring(0, fluid.Dilution.indexOf('/')) : '0');
        
        return {
            fluidName: fluidName,
            sortableName: fluid.Name,
            fluidType: fluidType,
            fluidId: fluid.FluidId,
            sortableDilution: sortableDilution,
            fluidSeasonId: fluid.FluidSeasonId,
            fluidVersionId: fluid.FluidVersionId,
            fluidDilution: fluid.Dilution,
            fluidNotes: fluidNotes,
            fluidCautions: fluid.Cautions,
            fluidOrder: fluid.Order,
            MinHot: hotDetails.MinHot,
            MaxHot: hotDetails.MaxHot,
            holdoverTime: hotDetails.Message,
            mobileHoldoverTime: this.filterMessage(mobileFluidMessage || ''),
        };
    }
}
