import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { RootScope } from '../shared/RootScope.service';
import { CLIENT_CODE } from '../shared/ClientCode.const';
import { NotificationService } from 'swx.front-end-lib';

@Component({
    templateUrl: 'Register.component.html',
    styleUrls: ['./Register.component.css']
})
export class RegisterComponent implements OnInit {
    allowedDomains: string;
    usernameRegex: string;
    username: string;
    password: string;
    isUserAdded: boolean;

    constructor(
        private route: ActivatedRoute,
        private api: ApiService,
        private $root: RootScope,
        private notification: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.$root.currentClient.$promise.then(() => {
            this.usernameRegex = this.$root.currentClient.ClientDomains
                .map(clientDomain => "[0-9a-zA-Z\\.\\-+_]+@" + clientDomain.Domain.replace('.', '\\.'))
                .join('|');

            this.allowedDomains = this.$root.currentClient.ClientDomains
                .filter(clientDomain => !clientDomain.Hidden)
                .map(clientDomain => '@' + clientDomain.Domain)
                .join(', ');
        });
    }

    submit() {
        this.api.PortalUser.post({
            ClientCode: CLIENT_CODE,
            Email: this.username,
            Password: this.password
        }).then(result => {
            if (result.IsNew) {
                this.isUserAdded = true;
            } else {
                this.notification.show('The email is already in use. Please try again.', { type: 'error', sticky: true });
            }
        });
    }
}
