import { Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';
import { RootScope } from '../shared/RootScope.service';
import { ApiService } from '../shared/Api.service';
import { EllipsisPipe, FileDownloadService, GridComponent } from 'swx.front-end-lib';

@Component({
    templateUrl: 'MobileAppDownload.component.html',
})
export class MobileAppDownloadComponent implements OnInit {
    query: any;
    @ViewChild('grid', { static: true }) grid: GridComponent;
    columnDefs = [
	    { "colId": "MobileAppName", "field": "MobileAppName", "headerName": "Mobile app", "width": 150 },
	    { "colId": "AppId", "field": "AppId", "headerName": "AppId", "width": 150 },
	    { "colId": "Version", "field": "Version", "headerName": "Version", "width": 100 },
	    { "colId": "PortalComments", "field": "PortalComments", "headerName": "Comments", "width": 150, "valueFormatter": c => c.value == null ? '' : EllipsisPipe.instance.transform(c.value, 80) },
	    { "colId": "ExpirationDate", "field": "ExpirationDate", "headerName": "Expiration date", "width": 120, "valueFormatter": c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateFormat), "filterType": "date" },
	    { "colId": "BuildFilename", "field": "BuildFilename", "headerName": "Build", "width": 200, "cellRenderer": c => {
		    var container = document.createElement('a');
		    if (c.data?.BuildFileUploadId != null) {
			    container.innerHTML = c.data.BuildFilename;
			    container.addEventListener('click', () => this.api.MobileAppReleaseBuildUrl.get(c.data.Id).$promise
				    .then(response => this.fileDownloadService.downloadUrl(response.Url, response.Filename)));
		    }
		    return container;
	    } },
	    { "colId": "XArchiveFilename", "field": "XArchiveFilename", "headerName": ".xarchive", "width": 200, "cellRenderer": c => {
		    var container = document.createElement('a');
		    if (c.data?.XArchiveFileUploadId != null) {
			    container.innerHTML = c.data.XArchiveFilename;
			    container.addEventListener('click', () => this.api.MobileAppReleaseXArchiveUrl.get(c.data.Id).$promise
				    .then(response => this.fileDownloadService.downloadUrl(response.Url, response.Filename)));
		    }
		    return container;
	    } },
    ];

    constructor(
	    private $root: RootScope,
        public api: ApiService,
	    private fileDownloadService: FileDownloadService,
    ) {
    }

    ngOnInit() {
        this.grid.refresh();
    }
}
