import { Component, Input } from '@angular/core'
import {MobileWeatherInfo} from "../shared/Api.service";

@Component({
    templateUrl: 'WeatherTypeDialog.component.html'
})
export class WeatherTypeDialogComponent {
    @Input('container') container: JQuery;
    @Input('close') close;
    @Input() weatherTypes: MobileWeatherInfo[];
    selectedWeatherMenuItem;

    selectWeatherType(weatherType) {
        this.close(weatherType);
        this.container.dialog('close');
    }

    open(weatherType) {
        this.weatherTypes = weatherType.Items;
    }

    cancel() {
        this.container.dialog('close');
    }
}
