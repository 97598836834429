import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { CLIENT_CODE } from '../shared/ClientCode.const';
import { AuthenticationService } from 'swx.front-end-lib';


@Injectable({ providedIn: 'root' })
export class RootScope {
    dateTimeFormat = 'YYYY-MM-DD HH:mm';
    dateFormat = 'YYYY-MM-DD';
    timeFormat = 'HH:mm:ss';
    chartYearFormat = 'yyyy';
    chartDateFormat = 'yyyy-MM-dd';
    yearFormat = 'YYYY';
    monthFormat = 'yyyy-MM';
    currentClient = null;
    currentUser = null;
    
    constructor(
        private router: Router,
        private api: ApiService,
        private authentication: AuthenticationService
    ) {
        this.currentClient = this.api.Client.get(CLIENT_CODE);

        this.currentClient.$promise.then(result => {
            if (result === null) {
                this.router.navigateByUrl('/unknown');
            } else {
                this.authentication.getCurrentUser(CLIENT_CODE)
                    .then(user => this.currentUser = user, () => {});
            }
        }, () => {
            this.router.navigateByUrl('/unknown');
        });
        
        this.authentication.userChange.subscribe(user => this.currentUser = user);
    }
}
