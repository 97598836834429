import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ApiService, DispatchAirport, ClientHotCondition, FedExShift } from '../shared/Api.service';
import { IResourceItemList } from 'swx.front-end-lib';
import { HotBuilderService } from '../shared/HotBuilder.service';

var unaryCompare = (f: Function) => (v1, v2) => f(v1) < f(v2) ? -1 : f(v1) > f(v2) ? 1 : 0;

export class AirportAlert {
    Airport: DispatchAirport;
    Date: Date;
}

@Injectable({ providedIn: 'root' })
export class DispatchMonitorService {
    private airportsSubject: Subject<IResourceItemList<DispatchAirport>>;
    hotConditions: IResourceItemList<ClientHotCondition>;
    private airportConditions = new Map<number, ClientHotCondition>();
    alerts = new Array<AirportAlert>();

    constructor(
        private api: ApiService,
    ) {
    }

    public monitor(): Observable<IResourceItemList<DispatchAirport>> {
        if (this.airportsSubject == null) {
            this.airportsSubject = new Subject<IResourceItemList<DispatchAirport>>();
            setInterval(() => {
                this.refresh();
            }, 60 * 1000);
        }

        return this.airportsSubject.asObservable();
    }

    removeAlert(airportId) {
        var index = this.alerts.findIndex(a => a.Airport.Id === airportId as any);
        if (index !== -1) {
            this.alerts.splice(index, 1);
        }
    }

    refresh() {
        var airports = this.api.DispatchAirport.query();
        var fluids = this.api.Fluid.query();

        if (this.hotConditions == null) {
            this.hotConditions = this.api.ClientHotCondition.query();
        }

        return Promise.all([airports.$promise, fluids.$promise, this.hotConditions.$promise]).then(() => {
            airports.forEach(a => {
                var hasHots = false;
                var hasHotMessages = false;

                var hotCondition = this.hotConditions.find(hc => hc.Id === a.HotResponse.HotConditionId);
                if (hotCondition != null) {
                    a.HotResponse.HotConditionRank = hotCondition.Rank;
                    a.HotResponse.HotConditionColor = hotCondition.Color;
                    a.HotResponse.HotConditionIcon = hotCondition.Icon;
                    a.HotResponse.HotConditionName = hotCondition.Name;
                }

                a.HotResponse.HotResponseHots.forEach(hrh => {
                    var hotDetails = HotBuilderService.parseHot(hrh.Message);

                    hasHotMessages = hasHotMessages || !!hotDetails.Message;
                    hasHots = hasHots || hotDetails.MinHot > 0;

                    var fluid = fluids.find(f => f.Id === hrh.FluidId);
                    hrh.FluidTypeNumber = fluid.FluidTypeNumber;
                    hrh.MinHot = hotDetails.MinHot;
                    hrh.MaxHot = hotDetails.MaxHot;
                    hrh.NonHotMessage = hotDetails.Message;
                    hrh.IsGeneric = fluid.Name.toUpperCase().indexOf("GENERIC") !== -1;
                    hrh.IsComposite = fluid.WingMaterialType === 'Composite';
                    hrh.SortableDilution = hrh.FluidType === 'Type1' || !hrh.FluidDilution || hrh.FluidDilution.indexOf("/") === -1
                        ? 0
                        : parseInt(hrh.FluidDilution.substr(0, hrh.FluidDilution.indexOf("/")));

                    var fluidHotCondition = this.hotConditions.find(hc => hc.Id === hrh.HotConditionId);

                    if (fluidHotCondition != null) {
                        hrh.HotConditionRank = fluidHotCondition.Rank;
                        hrh.HotConditionColor = fluidHotCondition.Color;
                        hrh.HotConditionIcon = fluidHotCondition.Icon;
                        hrh.HotConditionName = fluidHotCondition.Name;

                        if (a.HotResponse.HotConditionRank == null || fluidHotCondition.Rank < a.HotResponse.HotConditionRank) {
                            a.HotResponse.HotConditionRank = fluidHotCondition.Rank;
                            a.HotResponse.HotConditionColor = fluidHotCondition.Color;
                            a.HotResponse.HotConditionIcon = fluidHotCondition.Icon;
                            a.HotResponse.HotConditionName = fluidHotCondition.Name;
                            a.HotResponse.FluidMessage = hrh.NonHotMessage;

                            hotCondition = fluidHotCondition;
                        }
                    }
                });

                if (a.HotResponse.HotConditionRank == null) {
                    a.HotResponse.HotConditionRank = Number.MAX_VALUE;
                }

                if (this.airportConditions.has(a.Id)) {
                    var oldHotCondition = this.airportConditions.get(a.Id);

                    var index = this.alerts.findIndex(alert => alert.Airport.Id === a.Id as any);
                    if (index !== -1 && (hotCondition == null|| oldHotCondition.Id !== hotCondition.Id)) {
                        this.alerts.splice(index, 1);
                    }
                    
                    if (hotCondition != null
                            && hotCondition.ShowAlertWhenReached
                            && (oldHotCondition == null || oldHotCondition.Id !== hotCondition.Id)) {
                        this.alerts.push({
                            Airport: a,
                            Date: new Date()
                        });
                    }
                }

                this.airportConditions.set(a.Id, hotCondition);

                a.IsFreezing = a.HotResponse.Temperature < 1;
                a.IsClear = (a.HotResponse.Type !== 'LWE'
                        && (a.WeatherTypeNameResult === 'CLR'
                            || a.WeatherTypeNameResult === 'OBS'
                            || a.WeatherTypeNameResult === 'OTHN'))
                    || a.HotResponse.WeatherType === 'CLR';

                if (a.IsClear) {
                    a.Severity = a.IsFreezing ? 1 : 0;
                } else if (a.HotResponse.Message) {
                    a.Severity = 5;
                } else if (hasHotMessages) {
                    a.Severity = hasHots ? 3 : 4;
                } else if (hasHots) {
                    a.Severity = 2;
                }

                a.Type1Hot = a.HotResponse.HotResponseHots
                    .filter(hrh => hrh.FluidType === 'Type1')
                    .sort(unaryCompare(hrh => hrh.IsGeneric
                        ? (hrh.IsComposite ? 3 : 2)
                        : (hrh.IsComposite ? 1 : 0)))[0];

                a.Type2Hot = a.HotResponse.HotResponseHots
                    .filter(hrh => hrh.FluidType === 'Type2')
                    .sort(unaryCompare(hrh => hrh.IsGeneric
                        ? (hrh.FluidDilution === "100/0" ? 2 : 3)
                        : (hrh.FluidDilution === "100/0" ? 0 : 1)))[0];

                a.Type3Hot = a.HotResponse.HotResponseHots
                    .filter(hrh => hrh.FluidType === 'Type3')
                    .sort(unaryCompare(hrh => hrh.IsGeneric
                        ? (hrh.FluidDilution === "100/0" ? 2 : 3)
                        : (hrh.FluidDilution === "100/0" ? 0 : 1)))[0];

                a.Type4Hot = a.HotResponse.HotResponseHots
                    .filter(hrh => hrh.FluidType === 'Type4')
                    .sort(unaryCompare(hrh => hrh.IsGeneric
                        ? (hrh.FluidDilution === "100/0" ? 2 : 3)
                        : (hrh.FluidDilution === "100/0" ? 0 : 1)))[0];
            });

            this.airportsSubject.next(airports);

            return airports;
        });
    }
}
