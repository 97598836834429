import { Component, Input } from '@angular/core'
import {
    SnowVisibilitiesInfo, SnowVisibilitiesMenuDayNightOption,
    SnowVisibilitiesMenuIntensity,
    SnowVisibilitiesMenuTemperatureOption, SnowVisibilitiesMenuValue
} from "../shared/Api.service";

@Component({
    templateUrl: 'IntensityDialog.component.html'
})
export class IntensityDialogComponent {
    @Input('container') container: JQuery
    @Input('close') close;
    @Input() selectedIntensity?: SnowVisibilitiesMenuIntensity;
    @Input() selectedVisibility?: SnowVisibilitiesMenuValue;
    @Input() selectedTemperatureOption: SnowVisibilitiesMenuTemperatureOption;
    @Input() selectedDayNightOption?: SnowVisibilitiesMenuDayNightOption;
    @Input() snowVisibilities: SnowVisibilitiesInfo;

    selectSnowVisibility(visibilityOption: SnowVisibilitiesMenuValue) {
        this.close({
            visibility: visibilityOption,
            intensity: null,
        });
        this.container.dialog('close');
    }

    selectIntensity(intensity: SnowVisibilitiesMenuIntensity) {
        this.close({
            visibility: null,
            intensity: intensity,
        });
        this.container.dialog('close');
    };
    
    cancel() {
        this.container.dialog('close');
    }
}
